import rp from 'request-promise';
import config from '../../config';
import store from '../../store';

let getBaseUrl = () => {
    return config.apiUrl;
}

let getBearerToken = () => {
    return `Bearer ${store.getters['auth/currentAuthenticatedUser'].signInUserSession.idToken.jwtToken}`
}

const SearchCriteriaService = {
    list: async () => {
        try {
            let requestOptions = {
                url: getBaseUrl() + 'v2/search_criteria',
                json: true,
                method: 'GET'
            };

            let response = await rp( requestOptions );
        
            return response;
        } catch (error) {
            console.error('SearchCriteriaService.list error', error)
        }
    },
    get_distinct: async (table, column) => {
        let requestOptions = {
            // TODO: v2
            url: getBaseUrl() + 'search_criteria/get_distinct',
            json: true,
            method: 'GET',
            qs: {
                table_name: table,  
                column_name: column
            }
        };

        let response = await rp( requestOptions );
    
        return response;
    },
    get_range: async (table, column) => {
        let requestOptions = {
            // TODO: v2
            url: getBaseUrl() + 'search_criteria/get_range',
            json: true,
            method: 'GET',
            qs: {
                table_name: table,
                column_name: column
            }
        };

        let response = await rp( requestOptions );
        response[0].min = parseFloat(response[0].min);
        response[0].max = parseFloat(response[0].max);
        return response;
    },

    
}

export { SearchCriteriaService };
