// import Vue from 'vue'
let AWS = require('aws-sdk');

let getCallerIdentity = async () => {

  // let AWS = Vue.prototype.$AWS

  // let credentials = new AWS.SharedIniFileCredentials({profile: 'emn-user-manager'});
  // AWS.config.credentials = credentials;

  AWS.config.update({
    region: 'us-west-2'
  });

  AWS.config.apiVersions = {
    sts: '2011-06-15',
  };

  let sts = new AWS.STS()

  let params = {};

  return new Promise((resolve, reject) => {
    sts.getCallerIdentity(params, (error, data) => {
      if (error)
        return reject(error)
      return resolve(data)
    });
  });
};

export default {
  getCallerIdentity
}