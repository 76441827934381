<template>
    <section class="section">
        <div class="container">
            <criteriaSearchBar></criteriaSearchBar>
            <div class="level results-top-bar">
                <div class="level-left">
                    <div class="level-item">
                        <h1 v-if="isLoading" class="is-italic">
                            Loading results...
                        </h1>
                        <h1 v-else class="is-italic">
                            {{tableData.length}} records currently displayed
                        </h1>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <b-button
                            label="Convert Reference Species"
                            @click="isRSIModalActive = true"
                            type="is-size-7 is-success is-outlined" />
                        <b-modal v-model="isRSIModalActive" :width="640" scroll="keep" has-modal-card>
                            <div class="modal-card">
                                <div class="modal-card-head">
                                    <p class="modal-card-title">Convert Reference Species</p>
                                    <button
                                        type="button"
                                        class="delete"
                                        @click="isRSIModalActive = false" />
                                </div>
                                <rsiModalBody/>
                            </div>
                        </b-modal>
                    </div>
                    <div class="level-item">
                        <b-button
                            label="Select Columns"
                            @click="isManageColumnModalActive = true"
                            type="is-size-7 is-info is-outlined" />
                        <b-modal v-model="isManageColumnModalActive" :width="640" scroll="keep" has-modal-card>
                            <div class="modal-card">
                                <div class="modal-card-head">
                                    <p class="modal-card-title">Select columns for display</p>
                                    <button
                                        type="button"
                                        class="delete"
                                        @click="isManageColumnModalActive = false"/>
                                </div>
                                <columnManager/>
                            </div>
                        </b-modal>
                    </div>
                    <div class="level-item">
                        <b-dropdown v-model="perPage" aria-role="list">
                            <template #trigger>
                                <b-button
                                    :label="'Rows per page: ' + perPage"
                                    type="is-size-7 is-info is-outlined"
                                    icon-right="menu-down" />
                            </template>

                            <b-dropdown-item :value="20" aria-role="listitem" class="is-size-7">20</b-dropdown-item>
                            <b-dropdown-item :value="50" aria-role="listitem" class="is-size-7">50</b-dropdown-item>
                            <b-dropdown-item :value="100" aria-role="listitem" class="is-size-7">100</b-dropdown-item>
                            <b-dropdown-item :value="200" aria-role="listitem" class="is-size-7">200</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="level-item">
                        <b-dropdown aria-role="list">
                            <template #trigger>
                                <b-button
                                    label="Download Results"
                                    type="is-size-7 is-info"
                                    icon-right="menu-down" />
                            </template>

                            <b-dropdown-item
                                :value="'JSON'" 
                                aria-role="listitem" 
                                class="is-size-7" 
                                @click="downloadResultsJSONClicked">
                                    JSON
                                </b-dropdown-item>
                            <!-- <b-dropdown-item :value="'CSV'" aria-role="listitem" class="is-size-7">CSV</b-dropdown-item> -->
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <!-- <tooltip /> -->
            <div>
                <b-table
                    :data="tableData"
                    :scrollable=true
                    :striped=false
                    :hoverable=false
                    :narrowed=true
                    :paginated=true
                    :pagination-simple=false
                    :per-page="perPage"
                    detailed
                    detail-key='id'
                    :show-detail-icon="true"
                    :loading="isLoading"
                    :row-class="rowClass"
                    sticky-header height="800px"
                    ref="searchTable"
                >
                    <b-table-column v-for="tableColumn in tableDataColumns" 
                        :label="tableColumn.label" 
                        :key="tableColumn.field"
                        :field="tableColumn.field"
                        :visible="tableColumn.visible"
                        :sortable="tableColumn.sortable"
                        :searchable="tableColumn.searchable"
                    >
                        <template v-slot:header="{ column }">
                            <!-- <span v-b-tooltip.hover :title="tableColumn.tooltip">
                                {{ column.label = tableColumn.label }}
                            </span> -->
                            <ccbTooltip
                                type="column-header-content"
                                :content="tableColumn"
                                :label="column.label = tableColumn.label"
                            />
                        </template>

                        <template v-slot="props">
                            <ccbTooltip v-if="tableColumn.field === 'adsorbate'"
                                type="adsorbate-species-content"
                                :content="adsorbateSpeciesById(props.row.id)"
                                :label="props.row[tableColumn.field]"
                            />
                            
                            <ccbTooltip v-else-if="tableColumn.field === 'reference_species'"
                                type="adsorption-reference-species-content"
                                :content="adsorptionMeasurementById(props.row.id).adsorption_reference_species_set"
                                :label="coefficientFormula(props.row.id)"
                            />

                            <a id="doi-link" v-else-if="tableColumn.field === 'doi'"
                                target="_blank"
                                :href="'https://doi.org/' + props.row[tableColumn.field]"
                            >
                            {{props.row[tableColumn.field]}}
                            </a>
                            <div v-else>{{props.row[tableColumn.field]}}</div>
                        </template>
                    </b-table-column>

                    <template slot="detail" slot-scope="props">
                        <adsorptionMeasurementDetail 
                            :adsorptionMeasurement="adsorptionMeasurementById(props.row.id)"
                            :coefficientFormula="coefficientFormula(props.row.id)"
                            :adsorbateSpecies="adsorbateSpeciesById(props.row.id)"
                            :materialProperties="materialPropertiesById(props.row.id)"
                            :surfaceProperties="surfacePropertiesById(props.row.id)"
                            :adsorptionMethods="adsorptionMethodsById(props.row.id)"
                            :adsorptionMetadata="adsorptionMetadataById(props.row.id)"
                            :adsorptionEnergy="adsorptionEnergyById(props.row.id)"
                        >
                        </adsorptionMeasurementDetail>
                    </template>

                    <template slot="empty">
                        <section class="section">
                            <div v-if="isLoading">
                            </div>
                            <div v-else>
                                <div class="content has-text-grey has-text-centered">
                                    <b-icon
                                        icon="emoticon-sad"
                                        size="is-large">
                                    ></b-icon>
                                    <div v-if="isAPIUnreachable">
                                        <p class="has-text-weight-bold">Could not access database</p>
                                        <p>API is unavailable. You can try reloading the page or wait a few minutes.</p>
                                        <p>If you are still having trouble, email <a href="mailto:cpd@nrel.gov">cpd@nrel.gov</a>.</p>
                                    </div>
                                    <div v-else>
                                        <p class="has-text-weight-bold">Nothing found</p>
                                        <p>Please check your search criteria.</p>
                                        <p>You may also need to turn off the standard filters.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </template>
                </b-table>
            </div>
        </div>
    </section>
</template>

<script>
import rsiModalBody from './rsi-modal-body';
import criteriaSearchBar from './criteria-search-bar';
import columnManager from './column-manager';
import { mapGetters, mapActions } from 'vuex';
import adsorptionMeasurementDetail from '../details/adsorption-measurement-detail';
import ccbTooltip from '../tooltips/ccb-tooltip.vue';
import FileSaver from 'file-saver';

export default {
    name: "search_database",
    components: {
        ccbTooltip,
        criteriaSearchBar,
        columnManager,
        adsorptionMeasurementDetail,
        rsiModalBody
    },
    data() { 
        return {
            perPage: 20,
            isManageColumnModalActive: false,
            isRSIModalActive: false
        } 
    },
    async mounted() {
        try {
            // TODO: check for existing criteria so we won't call api again
            await this.fetchSearchCriteria({});
            let searchCriteria = this.searchCriteria;
            //console.log("searchCriteria: ", searchCriteria);
            await this.setTableDataColumns({searchCriteria});
            let tableDataColumns = this.tableDataColumns;
            //console.log("tableDataColumns:", tableDataColumns);

            // Before the initial table load, fetch the filters created by
            // the defaults in the preselected search criteria.
            let filters = this.filters;
            //console.log("filters: ", filters);
            await this.fetchTableData({filters, tableDataColumns});

            //await this.sortSearchTable();
            
            // Watch for JSON data to download
            this.$watch('jsonForDownload', this.saveJSON)
        }
        catch (error) {
            this.$log.error(error);
            // TODO: add back when auth is needed
            // this.$router.push('user-sign-in');
        }      
    },    
    computed: {
        ...mapGetters('adsorption-measurement', [
            'tableData',
            'jsonForDownload',
            'tableDataColumns',
            'adsorptionMeasurementById',
            'adsorbateSpeciesById',
            'isLoading',
            'isAPIUnreachable',
            'coefficientFormula',
            'materialPropertiesById',
            'surfacePropertiesById',
            'adsorptionMethodsById',
            'adsorptionMetadataById',
            'adsorptionEnergyById'
        ]),
        ...mapGetters('criterion', [
            'searchCriteria',
            'filters'
        ])
    },
    methods: {
        rowClass(row) {
            if (typeof row.rsi !== 'undefined') {
                return row.rsi ? 'is-converted' : 'is-not-converted';
            }
        },

        tooltipFormatter(value) {
            return `<b-tooltip target="tooltip-cell">${value}</b-tooltip>`;
        },

        ...mapActions('adsorption-measurement', [
            'fetchTableData',
            'setTableDataColumns',
            'downloadResultsJSON'
        ]),
        ...mapActions('criterion', [
            'fetchSearchCriteria'
        ]),

        downloadResultsJSONClicked() {
            let { filters } = this;
            this.downloadResultsJSON({ filters });
            // After the results are downloaded, the $watch on jsonForDownload
            // will kick off the save funcitonality.
        },

        async saveJSON(results) {
            // Create the timestamp for the file.
            // Hack toISOString to give a local-time string by first subtracting the local time offset from Date.now()
            // Also remove the final Z indicating zero offset and replace ':' with '-'
            let offset = (new Date()).getTimezoneOffset() * 60000 // local time offset in milliseconds
            let timestamp = (new Date(Date.now() - offset)).toISOString().slice(0, -1).replace(/:/gi, '-');
            let filename = `cpd-${timestamp}.json`

            // Create a blob of the JSON that needs to be saved.
            let { filters } = this;
            let jsonToSave = { filters, results };
            let blob = new Blob([JSON.stringify(jsonToSave, null, 2)], {type : 'application/json;charset=utf-8'});
            
            // Save the blob
            FileSaver.saveAs(blob, filename);
        }
        // ,

        // async sortSearchTable() {
        //     // Set default sorting column and direction
        //     // console.log(this.$refs.searchTable);
        //     // console.log(this.tableDataColumns);
        //     this.$refs.searchTable.sort("bulk_formula", false);
        // }
    }
}
</script>


<style> 
.is-converted {
    background: rgb(230, 252, 230);
    color: rgb(10, 112, 10);
}

.is-not-converted {
    background: rgb(252, 230, 230);
    color: rgb(112, 10, 10);
}

.tooltip {
    background: '#fff'
}

/* Results top bar */
.level.results-top-bar {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

/* Keep modal on top even during table loading */
.modal.is-active {
    z-index: 1001;
}

/* Fix sort icon position */
th.is-current-sort .th-wrap span .icon.sort-icon {
    position: relative;
    left: unset;
    right: unset;
    -webkit-transform: unset;
    transform: unset;
}
th.is-current-sort .th-wrap span .icon.sort-icon.is-desc {
    -webkit-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
}
</style>
