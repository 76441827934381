<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        CPD Release Notes
      </h1>
      <div class="block">
        Here you can find details of changes in each new release of the CPD. You can always check which version of the database web app and API you are using by scrolling to the bottom of the screen, in the orange bar.
      </div>
      <div class="block content">
        <h2>Release Notes - [Version/Date]</h2>

<h3>New Features and Improvements:</h3>
        <h3>
          1.4.0 - 2024/08/31 - CURRENT
        </h3>
        <ul>
          <li><strong>Reference species conversion with multiple species support.</strong> 
              A new feature allowing users to convert reference species has been introduced, now with the ability to handle multiple species terms simultaneously. This enhancement improves flexibility in RSI analysis, and allows for more complex calculations.
          </li>
          <li><strong>Gas phase energy data exploration added.</strong> 
              Gas phase energy data viewing and exploration capability has been added to the database, expanding the range of directly visible data for calculations.
          </li>
          <li><strong>Experimental data exploration added.</strong> 
              Experimental data viewing and exploration capability has been added to the database, offering more comprehensive data options for research.
          </li>
          <li><strong>Major backend database overhaul.</strong> 
              A significant backend database overhaul has been completed. The database has been refactored to use JSON data directly instead of normalized values. This makes the database much more flexible in terms of data structures that can be ingested. This upgrade allows for faster, more efficient data loading and processing, and makes for a better user experience when handling large datasets.
          </li>
        </ul>
        <h3>
          1.3.0 - 2024/03/31
        </h3>
        <ul>
          <li>
            Prototype of reference species conversion has been added.
          </li>
          <li>
            Additional database data, including initial experimental data has been added.
          </li>
          <li>
            Several performance improvements and quality of life enhancements.
          </li>
        </ul>
        <h3>
          1.2.0 - 2022/03/31
        </h3>
        <ul>
          <li>
            <b>Major overhaul of the search and filter user interface.</b> Search now has a clean, updated interface, with new features.
          </li>
          <li>
            The most common search settings have now been moved to a <b>preselected Filters section</b> below the main Search Criteria section. When you load the Search page, the site now defaults to showing only the most stable sites (see <a href="/parameter-guide">parameter guide</a>), coverages of 1/3 or less, and normal (equilibrium) unit cells that have not been stretched or compressed. The preselected Filters are designed to make it easier to find the most relevant results, but can easily be edited or removed.
          </li>
          <li>
            When selecting items from a list, such as a search by Adsorbate, you can now choose whether to filter the items in the list by Starts With (default; typing "H2" will return only entries like "H2", "H2O", etc.) or Contains (typing "H2" will also return entries like "CH2", "CH2O", etc."). Once entries are selected, this option has no effect on the search.
          </li>
          <li>
            <b>The results list can now be downloaded</b> in <a href="https://www.json.org/">JSON</a> format. Direct download in CSV format will be added in the future. There are many programs available to convert JSON to CSV, Excel, or other formats.
          </li>
          <li>
            Added 514 new datapoints
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          1.1.0 - 2021/09/30
        </h3>
        <ul>
          <li>
            <b>Added a User Guide</b> at <a href="https://cpd-docs.chemcatbio.org" target="_blank">https://cpd-docs.chemcatbio.org</a>
          </li>
          <li>
            <b>Opened the database to community uploads.</b> Go to <a href="https://github.com/NREL/cpd-batch-upload" target="_blank">cpd-batch-upload on GitHub</a> to get started, or <a href="mailto:cpd@nrel.gov">contact us</a> for help.
          </li>
          <li>
            Added 1088 new datapoints (582 in June and 506 in September)
          </li>
          <li>
            Fixed an issue that could prevent horizontal scrolling of the results list from working.
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          1.0.3 - 2021/05/24
        </h3>
        <ul>
          <li>
            Added a Parameter Guide that explains the meaning of each column / search criterion in the CPD.
          </li>
          <li>
            Display Adsorbate and Reference Species details via a hovertext dialog (simply hover your mouse over the table entry - look for dashed underlining - and details will appear)
          </li>
          <li>
            Coverage Fraction can now be searched and added to the table as either a fraction string or a numeric value.
          </li>
          <li>
            Renamed "No Bond SMILES" to "Connectivity SMILES" (see Parameter Guide for more details on this field)
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          1.0.2 - 2021/02/01
        </h3>
        <ul>
          <li>
            Standardized adsorbate and reference species data, including name formatting, SMILES validation, etc.
          </li>
          <li>
            Added column selection, reordering, and removal dialog as "Modify Columns" card
          </li>
          <li>
            Changed URL from compcat.chemcatbio.org to cpd.chemcatbio.org, including a redirect
          </li>
          <li>
            Improved display of reference species set formula
          </li>
          <li>
            All data for a database record is now available via the orange dropdown icon
          </li>
          <li>
            Clicking the DOI opens it in a new tab
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          1.0.1 - 2020/11/20
        </h3>
        <ul>
          <li>
            Improved vertical scrolling, sorting, and pagination
          </li>
          <li>
            Added dropdown to view full adsorbate and reference species info
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          1.0.0 - 2020/09/30
        </h3>
        <ul>
          <li>
            Initial public release of the CPD by the ChemCatBio Data Hub project.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'release-notes-index'
}
</script>

<style>

</style>
