import { get } from 'core-js/fn/dict';
import rp from 'request-promise';
import config from '../../config';
import store from '../../store';

let healthcheck = {};

let getBaseUrl = () => {
    return config.apiUrl;
}

let getBearerToken = () => {
    return `Bearer ${store.getters['auth/currentAuthenticatedUser'].signInUserSession.idToken.jwtToken}`
}

healthcheck.ping = async () => {
    let requestOptions = {
        json: true
    };

    let response = await rp(getBaseUrl(), requestOptions);

    return response;
}

healthcheck.version = async () => {
    let requestOptions = {
        json: true
    };
    
    let response = await rp(getBaseUrl() + 'version', requestOptions);

    return response;
}

export default healthcheck;