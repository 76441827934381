import rp from 'request-promise';
import config from '../../config';

const getBaseUrl = () => {
    return `${config.apiUrl}`;
}

const SpeciesService = {
    list: async (all_species) => {
        let requestOptions = {
            url: getBaseUrl() + 'v2/species',
            qs: {
                all_species
            },
            json: true,
            method: 'GET',
        };
    
        let response = await rp( requestOptions );

        if (response && Array.isArray(response)) {
            // Sort the array by the 'formula' property
            response.sort((a, b) => {
                if (a.formula < b.formula) return -1;
                if (a.formula > b.formula) return 1;
                return 0;
            });
        }

        return response;
    }
}

export { SpeciesService };
