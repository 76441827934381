// https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
import { UserService } from '../../services/user'
import STS from '../../services/aws/sts';
import Vue from 'vue';

const state = {
  authenticating: false,
  loggedIn: false,
  accessToken: '',
  authenticationErrorCode: 0,
  authenticationError: '',
  cognitoUser: {},
  signupUsername: '',
  signupEmail: '',
  currentAuthenticatedUser: {},
  currentUserCredentials: {},
  currentSession: {},
  username: '',
  selectedAppClient: {},
  identity: {}
}

const getters = {
  selectedAppClient: (state) => {
    return state.selectedAppClient;
  },
  identity: (state) => {
    return state.identity;
  },
  hasCredentials: (state) => {
    if (state.currentUserCredentials.secretAccessKey) {
      return true
    }
    return false
  },
  loggedIn: (state) => {
    return state.loggedIn;
  },
  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode
  },
  authenticationError: (state) => {
    return state.authenticationError
  },
  authenticating: (state) => {
    return state.authenticating
  },
  cognitoUser: (state) => {
    return state.cognitoUser
  },
  cognitoUserAttributes: (state) => {
    return state.cognitoUser.attributes || {}
  },
  cognitoUserClient: (state) => {
    return state.cognitoUser.client || {}
  },
  signupUser: (state) => {
    return {
      username: state.signupUsername,
      email: state.signupEmail
    }
  },
  currentAuthenticatedUser: (state) => {
    return state.currentAuthenticatedUser;
  },
  currentUserCredentials: (state) => {
    return state.currentUserCredentials;
  },
  currentSession: (state) => {
    return state.currentSession;
  }
}

const actions = {
  async reset ({ commit }) {
    commit('signupRequest');
  },
  async refresh ({ commit }, { cognitoUser }) {
    //console.log('refresh');
    commit('loginSuccess', cognitoUser);
  },
  async refreshSession ({ commit }, { currentSession }) {
    //console.log('refreshSession');
    commit('currentSession', currentSession);
  },
  async login ({ commit }, { username, password }) {
    try {
      commit('loginRequest');
      const cognitoUser = await UserService.login(username, password);
      commit('loginSuccess', cognitoUser);
      return cognitoUser
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async confirmSignIn ({ commit }, { user, code, mfaType }) {
    //console.log('confirmSignIn', user, code, mfaType);
    commit('loginRequest')

    try {
      const cognitoUser = await UserService.confirmSignIn(user, code, mfaType)
      commit('loginSuccess', cognitoUser)
      return cognitoUser
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async signup ({ commit }, { username, password, email, name }) {
    commit('signupRequest');
    try {
      const cognitoUser = await UserService.signup(username, password, email, name)
      commit('signupSuccess', {cognitoUser, username, email})
      return true
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async confirmSignup ({ commit }, { username, code }) {
    //console.log('auth/confirmSignup', username, code)
    commit('signupRequest');
    try {
      await UserService.confirmSignup(username, code)
      //console.log('auth/confirmSignup.data', data);
      // commit('signupSuccess', {username, email})
      return true
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async setupTOTP ({ commit }, { user }) {
    try {
      let code = await UserService.setupTOTP(user);
      commit('signupSuccess', {user})
      return code
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async verifyTotpToken ({ commit }, { user, challenge }) {
    try {
      await UserService.verifyTotpToken(user, challenge);
      commit('signupSuccess', {user})
      return true
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async logout ({ commit }) {
    //console.log('logout');
    try {
      await UserService.logout()
      commit('logoutSuccess')
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async getCurrentAuthenticatedUser ({ commit }, options) {
    //console.log('auth/getCurrentAuthenticatedUser', options)
    try {
      const currentAuthenticatedUser = await UserService.currentAuthenticatedUser(options);
      commit('currentAuthenticatedUser', currentAuthenticatedUser);

      commit('loginSuccess', currentAuthenticatedUser);
    } catch (error) {
      Vue.notify({
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async getCurrentUserCredentials ({ commit }, options) {
    //console.log('auth/getCurrentUserCredentials');
    try {
      const currentUserCredentials = await UserService.currentUserCredentials(options);

      let credentials = Vue.prototype.$Amplify.Auth.essentialCredentials(currentUserCredentials);
      Vue.prototype.$AWS.config.update({
        credentials: credentials
      });

      commit('currentUserCredentials', currentUserCredentials);
    } catch (error) {
      Vue.notify({ 
        group: 'error',
        title: error.name,
        text: error.message
      });
      return false
    }
  },
  async getCurrentSession ({ commit }) {
    try {

      const currentSession = await UserService.currentSession();
      commit('currentSession', currentSession);
      return true
    } catch (error) {
      //console.log('ERROR -- auth/getCurrentSession', error);
      commit('reset')
      throw new Error(error)
    }
  },
  async getIdentity ({ commit }) {
    const identity = await STS.getCallerIdentity();
    commit('setIdentity', identity);
  }
}

const mutations = {
  setSelectedAppClient (state, selectedAppClient) {
    state.selectedAppClient = selectedAppClient;
  },
  loginRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },
  loginSuccess (state, cognitoUser) {
    state.loggedIn = true
    state.authenticating = false
    // state.username = cognitoUser.attributes.name || cognitoUser.username;
    state.cognitoUser = cognitoUser
  },
  loginError (state, {errorCode, errorMessage}) {
    state.authenticationError = errorMessage
    state.authenticationErrorCode = errorCode
  },
  logoutSuccess (state) {
    state.authenticating = false;
    state.loggedIn = false;
    state.accessToken = '';
    state.authenticationErrorCode = 0;
    state.authenticationError = '';
    state.cognitoUser = {};
    state.signupUsername = '';
    state.signupEmail = '';
    state.currentAuthenticatedUser = {};
    state.currentUserCredentials = {};
    state.currentSession = {};
    state.identity = {};
  },
  signupRequest (state) {
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },
  signupSuccess (state, {cognitoUser, username, email}) {
    state.cognitoUser = cognitoUser;
    state.signupUsername = username;
    state.signupEmail = email;
  },
  signupError (state, {errorCode, errorMessage}) {
    state.authenticationError = errorMessage
    state.authenticationErrorCode = errorCode
  },
  currentAuthenticatedUser(state, currentAuthenticatedUser) {
    state.currentAuthenticatedUser = currentAuthenticatedUser;
    state.cognitoUser = currentAuthenticatedUser;
    // state.username = currentAuthenticatedUser.attributes.name;
  },
  currentUserCredentials(state, currentUserCredentials) {
    state.currentUserCredentials = currentUserCredentials;
  },
  currentSession(state, currentSession) {
    state.currentSession = currentSession;
  },
  reset(state) {
    state.authenticating = false;
    state.loggedIn = false;
    state.accessToken = '';
    state.authenticationErrorCode = 0;
    state.authenticationError = '';
    state.cognitoUser = {};
    state.signupUsername = '';
    state.signupEmail = '';
    state.currentAuthenticatedUser = {};
    state.currentUserCredentials = {};
    state.currentSession = {};
    state.username = '';
  },
  setIdentity(state, identity) {
    state.identity = identity;
  }
}

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default auth
