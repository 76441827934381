<!-- https://css-tricks.com/snippets/css/turn-off-number-input-spinners/ -->

<template>
    <b-dropdown aria-role="list">
        <template #trigger="{ active }">
            <b-button
                :icon-right="active ? 'menu-up' : 'menu-down'">
                <span class="preset-label">Coverage:</span> {{title}}
            </b-button>
        </template>
        
        <!-- The text field is inside a div, not a b-dropdown-item, so that it
            doesn't disappear when it is clicked. -->
        <div class="text-field-margin">
            <b-field label="Min">
                <b-input     
                    v-model="min"
                    type="number"
                    min="0"
                    step="0.01" 
                    @blur="updatePresetCoverage" />
                    <!-- use :placeholder="`value`" to add an example to the field -->
            </b-field>
        </div>

        <div class="text-field-margin">
            <b-field label="Max">
                <b-input 
                    v-model="max"
                    type="number"
                    min="0" 
                    step="0.01"
                    @blur="updatePresetCoverage" />
            </b-field>
        </div>

        <b-dropdown-item aria-role="listitem">
            <a href="#" @click.prevent="resetToDefault">Reset to default</a>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "presetSearchCoverage",
    props: ["min", "max"],
    async mounted() {
    },
    computed: {
        ...mapGetters('criterion', [
            'filters'
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),

        title() {
            let minLabel = this.min;
            let maxLabel = this.max;

            if (minLabel === '') {
                minLabel = '--';
            }

            if (maxLabel === '') {
                maxLabel = '--'
            }

            return `Min ${minLabel}, Max ${maxLabel}`
        }
    },
    methods: {
        ...mapActions('criterion', [
            'updatePresetCoverageAction'
        ]),

        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),

        async updatePresetCoverage() {
            let uuid = 'presetCoverage';
            let newMin = !!this.min ? parseFloat(this.min) : null;
            let newMax = !!this.max ? parseFloat(this.max) : null;
            let newNumbers = [newMin, newMax];
            await this.updatePresetCoverageAction({ uuid, newNumbers });
            let { filters, tableDataColumns } = this;
            await this.fetchTableData({ filters, tableDataColumns });
        },

        async resetToDefault() {
            let uuid = 'presetCoverage';
            this.min = 0;
            this.max = 0.34;
            let newNumbers = [this.min, this.max];
            await this.updatePresetCoverageAction({ uuid, newNumbers });
            let { filters, tableDataColumns } = this;
            await this.fetchTableData({ filters, tableDataColumns });
        }
    }  
}
</script>

<style scoped>
.text-field-margin {
    margin: 0.5em 1em 0.5em 1em;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
</style>
