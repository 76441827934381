<template>
  <nav class="navbar xis-fixed-top is-black" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a href="/" class="navbar-item">
          <span class="is-size-3 xhas-text-weight-bold has-text-primary">Catalyst Property Database</span>
        </a>

        <!-- <div class="navbar-burger burger ${navMenuIsActive ? 'is-active' : ''}" data-target="navMenuTransparentExample" click.delegate="toggleNavMenu()">
          <span></span>
          <span></span>
          <span></span>
        </div> -->
      </div>

      <div class="is-divider-vertical is-hidden-touch"></div>

        

      <div class="navbar-menu ${navMenuIsActive ? 'is-active' : ''}" id="subNavMenu">
        <div class="navbar-start">
          <!-- <template repeat.for="row of router.navigation">
            <a if.bind="row.settings.isTopNav" class="navbar-item xhas-text-weight-bold ${row.isActive ? 'is-active' : ''}" href.bind="row.href">
              <span>
                ${row.title}
              </span>
            </a>
          </template> -->
        </div>

        <div class="navbar-end has-navbar-right-padding">
          <div class="navbar-item">
            <a href="https://www.chemcatbio.org/">
              <img src="./assets/chemcatbio-logo-reverse-02.png" alt="ChemCatBio" width="269" height="98">
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'app-header'
  }
</script>

<style>
/* nrel global styles */

.nrel-row {
  margin-right: -15px;
  margin-left: -15px;
}


.nrel-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


@media (min-width:768px) {
  .nrel-container {
    width: 750px;
  }
}

@media (min-width:992px) {
  .nrel-container {
    width: 970px;
  }
}

@media (min-width:1200px) {
  .nrel-container {
    width: 1170px;
  }
}

/*nrel logo*/
.navbar-item img {
  max-height: 360px;
}

.logo {
  background: url("./assets/nrel-logo-lg.png") left no-repeat;
  height: 130px;
  width: 360px;
  background-size: 277px;
  background-position: center right;
  margin: 0 0 10px 0;
}

.navbar-end  {
  margin-right:1.85rem;
}

a.navbar-logo:hover,  a.navbar-logo:hover,  {
  background-color:none;
 }


 /*application name - use if name has no link*/
.app-name {
  color: #4B545A;
  display: block;
  font-family: Roboto,"Helvetica Neue",Helvetica,sans-serif;
  font-weight:400;
  font-size: 30px;
  line-height: 36px;
  margin: 0 0 0.3em 0;
  text-align: left;
  text-decoration: none;
  margin-top: 2rem;
}

@media only screen and (min-width : 100px) {
  .logo {
    height: 55px;
    background-size: 100px;
    background-position: center ;
    margin: 0 0 10px 0;
    }
  }

@media only screen and (min-width : 480px) {
.logo {
  height: 85px;
  background-size: 181px;
  background-position: center ;
  margin: 0 0 10px 0;
  }
}
@media (min-width: 768px) {
 .logo {
 /* background: url("/images/nrel-logo-lg.png") no-repeat left;*/
  height:130px;
  background-size: 277px;
  background-position: center ;
  }
}
</style>