<template>
    <b-field>
        <b-checkbox @input="updateCheckbox" 
            v-model="checkbox"
            type="is-info">
            {{checkbox}}
        </b-checkbox>
        <span :style="{display:'none'}">{{selectedCriterion}}</span>
    </b-field>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "selected_criterion_checkbox",
    props: ['selectedCriterion'],
    data() { 
        return {
            checkbox: true
        } 
    },
    async mounted() {
        await this.updateCheckbox(this.checkbox);
    },
    updated() {
        this.checkbox = this.selectedCriterion.checkbox;
    },
    computed: {
        ...mapGetters('criterion', [
            'filters'
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ])
    },
    methods: {
        ...mapActions('criterion', [
            'updateSelectedCheckbox'
        ]),
        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),
        async updateCheckbox(newCheckbox) {
            let uuid = this.selectedCriterion.uuid;
            await this.updateSelectedCheckbox({uuid, newCheckbox});
            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({filters, tableDataColumns});
        }
    }
}
</script>


<style scoped>
.field {
    margin-top: 9px;
}
</style>
