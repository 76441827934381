<template>
    <div class="container">
        <div class="level search-title">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="">
                        Search the CPD
                    </h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-button
                        label="Clear Search"
                        type="is-size-7 is-info"
                        @click= "_clearFilters"
                    />
                </div>
            </div>
        </div>
        <div class="regular-criteria">
            <h1 class="small-caps">
                search criteria
            </h1>
            <form onSubmit="return false;">
                <div v-for="selectedCriterion of selectedCriteria"
                    v-bind:key="selectedCriterion.uuid"
                    class="criterion-row">           
                    <searchCriterionItem :selected-criterion="selectedCriterion"></searchCriterionItem>
                </div>
            </form>
            <a @click="addSelectedCriteria">
                <span class="is-size-4 pl-1 pr-1">+</span>
                Add a criterion
            </a>
        </div>
        <div class="preset-criteria">
            <h1 class="small-caps">
                filters
            </h1>
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <presetSearchMostStable :onlyMostStable=getPresetMostStable()></presetSearchMostStable>
                    </div>
                    <div class="level-item">
                        <presetSearchCoverage :min = getMinCoverage() :max = getMaxCoverage()></presetSearchCoverage>
                    </div>
                    <div class="level-item">
                        <presetSearchUnitCell :normal = getUnitCellNormal() :stretched = getUnitCellStretched() 
                            :compressed = getUnitCellCompressed()>  </presetSearchUnitCell>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import searchCriterionItem from './search-criterion-item';
import presetSearchMostStable from './preset-search-most-stable'
import presetSearchCoverage from './preset-search-coverage'
import presetSearchUnitCell from './preset-search-unit-cell'


export default {
    name: "criteria_search_bar",
    props: ["selectedCriterion"],
    components: {
        searchCriterionItem,
        presetSearchMostStable,
        presetSearchCoverage,
        presetSearchUnitCell
    },
    async mounted() {
        await this._initializeFilters();
    },
    computed: {
        ...mapGetters('criterion', [
            'searchCriteria',
            'selectedCriteria',
            'filters',
            'presetCriteria'
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),      
    },
    methods: {
        ...mapActions('criterion', [
            'resetSelectedCriteria',
            'fetchSearchCriteria',
            'addSelectedCriteria',
            'updatePresetCoverageAction',
            'updatePresetCheckbox',
            'updatePresetUnitCell',
            'updateSelectedCheckbox'
        ]),
        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),
        async _fetchTableData() {
            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({ filters, tableDataColumns });
        },
        async _initializeFilters() {
            await this.resetSelectedCriteria();
            await this.addSelectedCriteria();
            await this.fetchSearchCriteria();
            this.$watch('selectedCriteria', this._fetchTableData);
        },
        async _resetPresetCoverageToDefault(){
            this.min = 0;
            this.max = 0.34;
            let newNumbers = [this.min, this.max];
            await this.updatePresetCoverageAction({ uuid: 'presetCoverage', newNumbers });
        },
        async _resetPresetCheckboxToDefault(){
            await this.updatePresetCheckbox({ uuid: 'presetMostStable', newCheckbox: true });  
        },
        async _resetPresetUnitCellToDefault() {
            await this.updatePresetUnitCell({ uuid: 'presetUnitCell', normal: true, stretched: false, 
                compressed: false });
        },
        async _clearFilters(){ 
            await this._resetPresetCheckboxToDefault();
            await this._resetPresetUnitCellToDefault();
            await this._resetPresetCoverageToDefault(); 
            await this._initializeFilters();
        },
        getPresetMostStable() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetMostStable'});
            return (presetCriteria.length > 0) ? presetCriteria[index].checkbox : true;
        },
        getMinCoverage() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetCoverage'});
            return (presetCriteria.length > 0) ? presetCriteria[index].numbers[0]: 0;
        },
        getMaxCoverage() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetCoverage'});
            return (presetCriteria.length > 0) ? presetCriteria[index].numbers[1] : 0.34;
        },
        getUnitCellNormal() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetUnitCell'});
            return (presetCriteria.length > 0) ? presetCriteria[index].normal : true;
        },
        getUnitCellStretched() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetUnitCell'});
            return (presetCriteria.length > 0) ? presetCriteria[index].stretched : false;
        },
        getUnitCellCompressed() {
            let presetCriteria = this.presetCriteria;
            let index = _.findIndex(presetCriteria, {uuid:'presetUnitCell'});
            return (presetCriteria.length > 0) ? presetCriteria[index].compressed : false;
        }
    }
}
</script>


<style>
/* Search title */
.level.search-title {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
}

/* Small caps headers */
.small-caps {
    font-variant: small-caps;
    margin-top: 1.2rem;
    margin-bottom: 0.25rem;
}

/* Style preset boxes and text */
.preset-criteria .button {
    border-width: 2px;
}
.preset-criteria .preset-label {
    color: #808080;
    font-style: italic;
}

/* Keep preset dropdowns on top of loading table */
.preset-criteria .dropdown-menu {
    z-index: 1000;
}

/* No margin-right on checkboxes */
.regular-criteria .b-checkbox.checkbox:not(.button),
.preset-criteria .b-checkbox.checkbox:not(.button) {
    margin-right: 0;
}
</style>
