import Vue from 'vue';

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = 'AuthenticationError'
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  login: async function (username, password) {
    console.log('UserService::login');
    try {
      const response = await Vue.prototype.$Amplify.Auth.federatedSignIn();
      console.log("RESPOSNE", response);
      // const response = await Vue.prototype.$Amplify.Auth.signIn(username, password);
      return response;
    } catch (error) {
      console.log('UserService:login', error)
      if (error.name === 'AuthError') {
        throw new AuthenticationError(400, error.message);
      }
      if (error.name === 'NotAuthorizedException') {
        throw new AuthenticationError(401, error.message);
      }
      if (error.name === 'UserNotFoundException') {
        throw new AuthenticationError(404, error.message);
      }
      throw new AuthenticationError(401, 'Not Authorized');
    }
  },
  confirmSignIn: async function (user, code, mfaType) {
    console.log('UserService::confirmSignIn');
    try {
      const response = await Vue.prototype.$Amplify.Auth.confirmSignIn(user, code, mfaType)
      return response
    } catch (error) {
      console.log(error)
      throw new AuthenticationError(error.response.status, 'Not Authorized')
    }
  },
  signup: async function (username, password, email, name) {
    console.log('UserService::signup');
    try {
      const response = await Vue.prototype.$Amplify.Auth.signUp({username, password, attributes: {email, name}})
      return response
    } catch (error) {
      console.log(error.name)
      if (error.name === 'AuthError') {
        throw new AuthenticationError(400, error.message)
      }
      if (error.name === 'UsernameExistsException') {
        throw new AuthenticationError(400, error.message)
      }
      throw new AuthenticationError(401, 'Not Authorized')
    }
  },
  confirmSignup: async function(username, code) {
    console.log('UserService::confirmSignup');
    try {
      const response = await Vue.prototype.$Amplify.Auth.confirmSignUp(username, code)
      return response
    } catch (error) {
      console.log('ERROR -- UserService.confirmSignup', error)
      if (error.name === 'AuthError') {
        throw new AuthenticationError(400, error.message)
      }
      if (error.name === 'UsernameExistsException') {
        throw new AuthenticationError(400, error.message)
      }
      if (error.name === 'NotAuthorizedException') {
        throw new AuthenticationError(400, error.message)
      }
      if (error.name === 'UserNotFoundException') {
        throw new AuthenticationError(400, error.message)
      }
      throw new AuthenticationError(401, 'Not Authorized')
    }
  },
  setupTOTP: async function(user) {
    console.log('UserService::setupTOTP');
    try {
      const response = await Vue.prototype.$Amplify.Auth.setupTOTP(user)
      return response
    } catch (error) {
      console.log(error.name)
      if (error.name === 'AuthError') {
        throw new AuthenticationError(400, error.message)
      }
      if (error.name === 'UsernameExistsException') {
        throw new AuthenticationError(400, error.message)
      }
      throw new AuthenticationError(401, 'Not Authorized')
    }
  },
  verifyTotpToken: async function(user, challenge) {
    console.log('UserService::verifyTotpToken');
    try {
      console.log('verifyTotpToken', user, challenge);
      const response = await Vue.prototype.$Amplify.Auth.verifyTotpToken(user, challenge)
      console.log(response);
      const response2 = await Vue.prototype.$Amplify.Auth.setPreferredMFA(user, 'TOTP');
      console.log(response2);
      return response
    } catch (error) {
      console.log(error)
      if (error.name === 'EnableSoftwareTokenMFAException') {
        throw new AuthenticationError(400, error.message)
      }
      throw new AuthenticationError(401, 'Not Authorized')
    }
  },
  logout: async function () {
    console.log('UserService::logout');
    await Vue.prototype.$Amplify.Auth.signOut()
  },
  currentAuthenticatedUser: async function (options = {bypassCache: true}) {
    console.log('UserService::currentAuthenticatedUser');
    try {
      return await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser(options);
    } catch (error) {
      console.error('error', error);
      throw new Error(error);
    }
  },
  currentUserCredentials: async function (options = {bypassCache: true}) {
    console.log('UserService::currentUserCredentials', options);
    try {
      // TODO: This Amplify function does not appear to properly throw errors. Instead,
      // it returns the error instead of throwing it. !?!?
      let currentUserCredentials = await Vue.prototype.$Amplify.Auth.currentUserCredentials(options);
      if (currentUserCredentials.statusCode >= 400) {
        throw currentUserCredentials;
      }
      return currentUserCredentials
    } catch (error) {
      console.error('error', error.name, error);
      throw error;
    }
  },
  currentSession: async function () {
    console.log('UserService::currentSession');
    try {
      return await Vue.prototype.$Amplify.Auth.currentSession();
    } catch (error) {
      console.error('error', error);
      throw new Error(error);
    }
  }

}

export { UserService, AuthenticationError }
