<template>
    <div>
        <div class="criterion-select">
            <b-select @input="updateSearchCriterion" placeholder="Select a criterion">
                <option
                    v-for="option in nonPresetSearchCriteria"
                    :value="option"
                    :key="option.Id">
                    {{ option.criteria }}
                </option>
            </b-select>
        </div>         
        <div v-if="selectedCriterion.selectedSearchCriterion.datatype === 'String'" class="criterion-input">
            <selectedCriterionTaginput 
                :selected-criterion="selectedCriterion">
            </selectedCriterionTaginput>
        </div>
        <div v-else-if="selectedCriterion.selectedSearchCriterion.datatype === 'Numeric'" class="criterion-input">
            <selectedCriterionNumeric 
                :selected-criterion="selectedCriterion">
            </selectedCriterionNumeric> 
        </div>
        <div v-else-if="selectedCriterion.selectedSearchCriterion.datatype === 'Boolean'" class="criterion-input">
            <selectedCriterionCheckbox
                :selected-criterion="selectedCriterion">
            </selectedCriterionCheckbox>
        </div>
        <!-- TODOUI: What about line length? And appending trash can to the end? Make fullwidth like Scopus? -->
        <div v-if="selectedCriteria.length>1 && selectedCriterion.selectedSearchCriterion.datatype" 
            class="criterion-spacer"/>
        <div v-if="selectedCriteria.length>1" class="criterion-trash">
            <a @click="removeCriterion">
                <b-icon
                    icon="trash-can-outline"
                    >
                </b-icon>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import selectedCriterionTaginput from './selected-criterion-taginput';
import selectedCriterionNumeric from './selected-criterion-numeric';
import selectedCriterionCheckbox from './selected-criterion-checkbox';

export default {
    name: "search_criterion_item",
    components: { 
        selectedCriterionTaginput,
        selectedCriterionNumeric,
        selectedCriterionCheckbox,
    },
    props: ['selectedCriterion'],
    // props: ['selectedCriterion', "min", "max","stretched", "compressed", "normal"],
    
    data() { 
        return {
            currentFieldName : ''
        } 
    },
    async mounted() {},
    computed: {
        ...mapGetters('criterion', [
            'searchCriteria',
            'selectedCriteria',
            'filters'
        ]),

        nonPresetSearchCriteria() {
            return this.searchCriteria.filter((criterion) => !criterion.preset);
        }
    },
    methods: {
        ...mapActions('criterion', [
            /* Alternative approach to maintaining 1+ icons: re-spawn single criterion if all removed
            /'addSelectedCriteria', */
            'resetSelectedCriteria',
            'fetchSearchCriteria',
            'updateSelectedCriteria',
            'updateSelectedCriteriaData',
            'removeSelectedCriterion'
        ]),
        ...mapActions('adsorption-measurement', [
            'toggleColumnVisible'
        ]),
        _toggleColumnVisible(fieldName, visible, visibility_type = 'search_visible') {
            this.toggleColumnVisible({fieldName, visible, visibility_type});
        },
        async updateSearchCriterion(newSearchCriterion) {
            if (this.currentFieldName) {
                this._toggleColumnVisible(this.currentFieldName, false);
            }
            let uuid = this.selectedCriterion.uuid;
            await this.updateSelectedCriteria({uuid, newSearchCriterion});
            this.currentFieldName = this.selectedCriterion.selectedSearchCriterion.display_field;
            this._toggleColumnVisible(this.currentFieldName, true);

        },
        async removeCriterion() {
            let uuid = this.selectedCriterion.uuid;
            await this.removeSelectedCriterion({uuid});
            /* Alternative approach to maintaining 1+ icons: // If all criteria removed, add one back
            if (this.selectedCriteria.length == 0) {
                this.addSelectedCriteria();
            } */
            this._toggleColumnVisible(this.currentFieldName, false);
        }
    }
}
</script>

<style>
/* Criterion row vertical spacing. 
Note that criterion-row is invoked in the parent, criteria-search-bar */
.criterion-row:not(:first-child) {
    margin-top: 12px;
}

/* Criterion row alignment */
.criterion-select, .criterion-input,
.criterion-spacer, .criterion-trash {
    display: inline-block;
    vertical-align: top;
}

/* Trash icon alignment */
.criterion-trash .icon {
    margin-top: 7px;
}

/* Criterion row horizontal spacing */
.criterion-select, .criterion-spacer {
    margin-right: 12px;
}

/* Grey, borderless input in search criteria */
.criterion-select select, 
.criterion-input .input,
.criterion-input .taginput-container.is-focusable,
.criterion-input .box {
    background-color: #f5f5f5;
    border: 0px;
    box-shadow: none;
}
.criterion-input .tag:not(body) {
    background-color: #e4e4e4;
}

/* Fix too-short taginput */
.criterion-input .taginput-container.is-focusable,
.criterion-input .box {
    min-height: 2.5em;
}
</style>
