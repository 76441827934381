<template>
    <b-dropdown aria-role="list">
        <template #trigger="{ active }">
            <b-button
                :icon-right="active ? 'menu-up' : 'menu-down'">
                <span class="preset-label">Unit cell:</span> {{label}}
            </b-button>
        </template>

        <section>
            <b-field class="checkbox-margin">
                <b-checkbox 
                    v-model="normalState"
                    :disabled="normalDisabled"
                    @input="updateCheckboxes"
                    type="is-info">Normal</b-checkbox>
            </b-field>
            <b-field class="checkbox-margin">
                <b-checkbox v-model="stretched" @input="updateCheckboxes" type="is-info">Stretched</b-checkbox>
            </b-field>
            <b-field class="checkbox-margin">
                <b-checkbox v-model="compressed" @input="updateCheckboxes" type="is-info">Compressed</b-checkbox>
            </b-field>
        </section>

        <b-dropdown-item aria-role="listitem">
            <a href="#" @click.prevent="resetToDefault">Reset to default</a>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "presetSearchUnitCell",
    props: ["stretched", "compressed", "normal"],
    computed: {
        ...mapGetters('criterion', [
            'filters'
        ]),

        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),

        label() {
            if (this.normal && this.stretched && this.compressed) {
                return 'any';
            }
            else if (this.normal && this.stretched) {
                return 'normal, stretched';
            }
            else if (this.normal && this.compressed) {
                return 'normal, compressed';
            }
            else if (this.stretched && this.compressed) {
                return 'stretched, compressed';
            }
            else if (this.normal || (!this.stretched && !this.compressed)) {
                return 'normal';
            }
            else if (this.stretched) {
                return 'stretched';
            }
            else if (this.compressed) {
                return 'compressed';
            }
            else {
                return '';
            }
        },

        normalDisabled() {
            return !this.stretched && !this.compressed;
        },

        normalState: {
            get() {
                if (!this.stretched && !this.compressed) {
                    return true;
                }
                else if (this.stretched && this.normal) {
                    return true;
                }
                else if (this.compressed && this.normal) {
                    return true;
                }
                else if (this.normal) {
                    return true;
                }
                else {
                    return false;
                }
            },

            set() {
                this.normal = !this.normal;
                return this.normal;
            }
        }
    },
    methods: {
        ...mapActions('criterion', [
            'updatePresetUnitCell'
        ]),
        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),
        async updateCheckboxes() {
            let uuid = 'presetUnitCell';
            let {normal, stretched, compressed } = this;
            
            // If nothing is selected, this means that normal has
            // been selected by default, so override its local state before
            // passing it to the store. Otherwise simply pass the states
            // of the checkboxes as they are into the store.

            if (!normal && !stretched && !compressed) {
                await this.updatePresetUnitCell({ uuid, normal: true, stretched, compressed })
                // this.normal = true;
            }
            else {
                await this.updatePresetUnitCell({ uuid, normal, stretched, compressed })
            }

            let { filters, tableDataColumns } = this;
            await this.fetchTableData({ filters, tableDataColumns });
        },

        async resetToDefault() {
            let uuid = 'presetUnitCell';

            this.normal = true;
            this.stretched = false;
            this.compressed = false;

            await this.updatePresetUnitCell({
                uuid,
                normal: this.normal,
                stretched: this.stretched,
                compressed: this.compressed
            })

            let { filters, tableDataColumns } = this;
            await this.fetchTableData({ filters, tableDataColumns });
        }
    }  
}
</script>

<style scoped>
.checkbox-margin {
    margin: 0.5em 1em 0.5em 1em;
}
</style>
