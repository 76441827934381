<template>
  <div class="container">
    <section class="section">

      <b-steps
        v-model="activeStep"
        animated
        :has-navigation="false">

        <b-step-item label="Sign Up">

          <div class="container">
            <section class="section">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="submitSignup">
                    <b-field label="Username">
                      <b-input
                        type="username"
                        v-model="data.username">
                      </b-input>
                    </b-field>

                    <b-field label="Name">
                      <b-input
                        type="name"
                        v-model="data.name">
                      </b-input>
                    </b-field>

                    <b-field label="Email">
                      <b-input
                        type="email"
                        v-model="data.email">
                      </b-input>
                    </b-field>

                    <b-field label="Password">
                      <b-input
                        type="password"
                        v-model="data.password">
                      </b-input>
                    </b-field>
                    <button class="button is-primary" type="submit">Sign Up</button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </b-step-item>

        <b-step-item label="Confirm Sign Up">

          <div class="container">
            <section class="section">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  We have sent a code by email to EMAIL. Enter it below to confirm your account.
                  <form @submit.prevent="submitConfirm">
                    <b-field label="Verification Code">
                      <b-input
                        v-model="data.verificationCode">
                      </b-input>
                    </b-field>
                    <button class="button is-primary" type="submit">Confirm account</button>
                  </form>
                  Didn't receive a code?
                  <button class="button is-primary is-inverted">Resend if</button>
                </div>
              </div>
            </section>
          </div>
        </b-step-item>

        <b-step-item label="Configure">

          <div class="container">
            <section class="section">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="submitTOTP">
                    <!-- <b-button class="is-secondary" @click="showCode()">Show Code</b-button> -->
                    <qrcode-vue :value="qrcode" size="200" level="H"></qrcode-vue>

                    <b-field label="challengeAnswer">
                      <b-input
                        type="challengeAnswer"
                        v-model="data.challengeAnswer">
                      </b-input>
                    </b-field>
                    <button class="button is-primary" type="submit">Submit Challenge</button>
                    <b-tooltip type="is-warning" label="Some features may be disabled">
                      <b-button class="is-primary is-inverted" @click="skipMFA(data.username, data.password)">Skip MFA</b-button>
                    </b-tooltip>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </b-step-item>

      </b-steps>

    </section>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'user-sign-in',
  data () {
    return {
      data: {
        username: '',
        password: ''
      },
      code: '',
      qrcode: '',
      activeStep: 0
    }
  },
  components: {
    QrcodeVue
  },
  beforeMount() {
    this.$store.dispatch('auth/reset'); // reset error messages
  },
  computed: {
    ...mapGetters('auth', [
      'signupUser',
      'cognitoUser',
      'currentAuthenticatedUser'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'signup',
      'confirmSignup',
      'setupTOTP',
      'verifyTotpToken',
      'getCurrentAuthenticatedUser'
    ]),
    async submitSignup () {
      try {
        let username = this.data.username;
        let password = this.data.password;
        let email = this.data.email;
        let name = this.data.name;
        const success = await this.signup({username, password, name, email});
        // console.log(this.authenticationError, this.authenticationErrorCode)
        if (success)
          this.activeStep = 1;
      } catch (error) {
        // console.error('submitSignup', error);
      }
    },
    async submitConfirm () {
      try {
        let username = this.data.username;
        let password = this.data.password;
        let code = this.data.verificationCode;
        const success = await this.confirmSignup({username, code});
        // console.log(this.authenticationError, this.authenticationErrorCode)
        if (success) {
          await this.login({username, password});
          await this.getCurrentAuthenticatedUser();
          await this.showCode();
          this.activeStep = 2;
        }
      } catch (error) {
        // console.error('submitConfirm', error);
      }
    },
    async submitTOTP () {
      try {
        let user = this.currentAuthenticatedUser;
        let challenge = this.data.challengeAnswer;
        const success = await this.verifyTotpToken({user, challenge});
        // console.log(this.authenticationError, this.authenticationErrorCode)
        if (success)
          this.$router.push('user-details')
      } catch (error) {
        // console.error('submitConfirm', error);
      }
    },
    async skipMFA (username, password) {
      await this.login({username, password})
    },
    async showCode () {
      try {
        let user = this.currentAuthenticatedUser;
        // console.log('currentAuthenticatedUser', this.currentAuthenticatedUser)

        this.code = await this.setupTOTP({user});
        this.qrcode = `otpauth://totp/AWSCognito:${this.signupUser.username}?secret=${this.code}&issuer=AWS`
      } catch (error) {
        // console.log('ERROR -- SignUp.vue::showCode', error);
      }
    }
  }
}
</script>

<style>

</style>
