<template>
    <div>
        <div class="modal-card-body">

            <p>Required Input:</p>
            <div class="box-around-inputs">
                <ul v-for="c in coefficientsAndAdsorbates" :key="c.uuid">
                    <li><coefficientAndAdsorbate :uuid="c.uuid"/></li>
                </ul>
                <!-- Add Reference Species button -->
                <div class="columns">
                    <div class="column">
                        <a class="add-absorbate" @click="addBlankCoefficientAndAdsorbate">+ Add Reference Species</a>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        Reference Species Set:
                    </div>
                    <div class="column">
                        <strong>{{ overallFormula }}</strong>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        Adsorbate Molecular Formula:
                    </div>
                    <div class="column">
                        <strong>{{ elementalFormula }}</strong>
                    </div>
                </div>
            </div>

            <!-- TODO: add the optional input later to support original design, NKG 1/18 per convo with RH -->
            <!-- <p style="margin: 1em 0 0 0;">Optional Input:</p>
            <div class="box-around-inputs">
                <div class="columns">
                    <div class="column">
                        Adsorbate to match:
                    </div>
                    <div class="column">
                        <b-select placeholder="Adsorbate">
                            <option v-for="species in allSpeciesData"
                                :key="species.id"
                                :value="{formula: species.formula, id: species.id}">
                                {{ species.formula }}
                            </option>
                        </b-select>
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        Adsorbate elemental formula:
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        &nbsp;
                    </div>
                    <div class="column">
                        Adsorbate formula match!
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                </div>
            </div> -->

            <div class="columns" style="margin: 1em 0 0 0;">
                <div class="column">
                    <b-button @click="convertReferenceSpeciesClicked">convert reference species</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import coefficientAndAdsorbate from './coefficient-and-adsorbate.vue';


export default {
    name: 'rsiModalBody',

    async mounted() {
        if (this.coefficientsAndAdsorbates.length < 1 || this.coefficientsAndAdsorbates[0].species === null){
            this.resetCoefficientsAndAdsorbates();
        }
        await this.fetchAllSpeciesData();
    },

    components: { coefficientAndAdsorbate },

    computed: {
        ...mapGetters('adsorption-measurement', [
            'coefficientsAndAdsorbates',
            'tableDataColumns',
            'overallFormula',
            'elementalFormula'
        ]),
        ...mapGetters('criterion', ['filters']),
        ...mapGetters('species', ['allSpeciesData'])
    },

    methods: {
        ...mapActions('species', ['fetchAllSpeciesData']),

        ...mapActions('adsorption-measurement', [
            'addBlankCoefficientAndAdsorbate', 
            'resetCoefficientsAndAdsorbates',
            'fetchTableData',
            'updateOverallFormula'
        ]),

        async convertReferenceSpeciesClicked() {
            const options = {
                filters: this.filters,
                rsi: true,
                tableDataColumns: this.tableDataColumns
            };
            console.log('convertReferenceSpeciesClicked', options);
            await this.fetchTableData(options);
            this.$parent.close();
        }
    }
}
</script>

<style>
.add-absorbate {
    margin: 1em auto 1em auto;
}

.box-around-inputs {
    border: 1px solid gray;
    padding: 1em;
}
</style>
