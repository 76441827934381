<template>
  <section class="hero is-light is-bold is-fullheight-with-navbar">
    <div class="hero-body">
            <div class="container">
                <h1 class="title">
                    healthcheck
                </h1>
                <h2 class="subtitle">
                    {{ healthcheck }}
                </h2>
                <hr/>
                <img src="../../assets/logo.png" class="image" width="400"/>
            </div>
    </div>
  </section>
</template>

<script>

import healthcheck from '../../services/compcat/healthcheck'
export default {
  name: 'healthcheck',
  data() {
      return {
          healthcheck: {}
      }
  },
  async mounted() {
      this.healthcheck = await healthcheck.ping();
  }
}
</script>


<style>

</style>
