<template>
    <div class="button preset-label">
        <b-checkbox v-model ="onlyMostStable" @input="updateMostStable" type="is-info">Only most stable site</b-checkbox>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "presetSearchMostStable",
    props : ["onlyMostStable"],
    computed: {
        ...mapGetters('criterion', [
            'filters'
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),
    },
    methods: {
        ...mapActions('criterion', [
            'updatePresetCheckbox'
        ]),

        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),
        
        async updateMostStable(userInput) {
            // Since this is the preselected search criterion for is_most_stable
            // this update is always targeted at the preselected search criteria.
            let newCheckbox;
            if (userInput) {
                newCheckbox = true;
            } else {
                newCheckbox = null;
            }
            await this.updatePresetCheckbox({uuid: "presetMostStable", newCheckbox});
            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({filters, tableDataColumns});
        }
    }  
}
</script>

