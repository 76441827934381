<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Surface/Particle Properties
            </p>
        </header>
        <div class="card-content">
            <b-field>
                <div class="container">
                    <div class="container">Facet: {{surfaceProperties.facet}}</div>  
                    <div class="container">Surface Termination: {{surfaceProperties.termination}}</div>
                    <div class="container">Surface Composition: {{surfaceProperties.firstLayerComposition}}</div>
                    <div class="container">Sub-Surface Composition: {{surfaceProperties.secondLayerComposition}}</div>
                    <div class="container">Cell Symmetry: {{surfaceProperties.cellSymmetry}}</div>
                    <div class="container">Nanoparticle Size: {{surfaceProperties.nanoParticleSize}} <span v-if="surfaceProperties.nanoParticleSize">atoms</span></div>
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'surface-properties-detail',
        props: ['surfaceProperties']
    }
</script>


<style>

</style>
