import { SpeciesService } from '../../services/compcat/species-service';

const state = {
    speciesData: [],
    allSpeciesData: [],
    isAPIUnreachable: false
}

const getters = {
    speciesData(state) {
        return state.speciesData;
    },
    allSpeciesData(state) {
        return state.allSpeciesData;
    }
}

const actions = {
    async fetchSpeciesData({ commit }) {
        const all_species = 'false';
        // const all_species = 'true'; // TODO: gas_phase_energy table is not populated
        const data = await SpeciesService.list(all_species);
        commit('setSpeciesData', { data });
    },
    async fetchAllSpeciesData({ commit }) {
        const all_species = 'true';
        const data = await SpeciesService.list(all_species);
        commit('setAllSpeciesData', { data });
    }
}

const mutations = {
    setSpeciesData(state, { data }) {
        state.speciesData = data;
    },
    setAllSpeciesData(state, { data }) {
        state.allSpeciesData = data;
    }
    
}

const species = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default species;
