<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Methods
            </p>
        </header>
        <div class="card-content">
            <b-field>
                <div class="container">
                    <div class="container">Software: {{adsorptionMethods.software}}</div>
                    <div class="container">Potentials: {{adsorptionMethods.potentials}}</div>
                    <div class="container">Exchange Correlation Functional: {{adsorptionMethods.exchangeCorrelation}}</div>
                    <div class="container">Basis Set: {{adsorptionMethods.basisSet}}</div>
                    <div class="container">Spin Polarized: {{adsorptionMethods.spinPol}}</div>
                    <div class="container">Zero Point Energy Corrected: {{adsorptionMethods.zpe}}</div>
                    <div class="container">Fixed Substrate: {{adsorptionMethods.fixedSubstrate}}</div>
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'methods-detail',
        props: ['adsorptionMethods']
    }
</script


<style>

</style>
