<template>
    <div class="box is-paddingless">
        <!-- TODOUI: why doesn't this element wrap? Ideally, it 
        would increase the height of the entire level, including 
        the Select a Criterion box -->
        <b-taginput @input="updateTags"
            v-model="inputTags"
            :data="getTagList"
            autocomplete
            :open-on-focus=true
            icon="label"
            placeholder="Enter search data"
            @typing="filterTags">
            <template #empty>
                No matching options
            </template>
        </b-taginput>
        <b-dropdown v-model="startsWith" :triggers="['hover']" aria-role="list" class="mr-1 ml-1">
            <template v-if="startsWith" #trigger>
                <b-button
                    label="Starts With"
                    type="is-size-7 is-info is-outlined"
                    icon-right="menu-down" />
            </template>

            <template v-else #trigger>
                <b-button
                    label="Contains"
                    type="is-size-7 is-info is-outlined"
                    icon-right="menu-down" />
            </template>

            <b-dropdown-item custom class="is-size-7">
                <strong>Autocomplete Using:</strong>
            </b-dropdown-item>
            <b-dropdown-item :value="true" aria-role="listitem" class="is-size-7">Starts With</b-dropdown-item>
            <b-dropdown-item :value="false" aria-role="listitem" class="is-size-7">Contains</b-dropdown-item>
            <b-dropdown-item custom style="font-size: 0.6rem;">
                This selection affects the filtering of the dropdown list. If typing 'H2', Starts With will return only entries that start with 'H2' (H2, H2O, etc.), while Contains will also return entries that contain 'H2' (CH2, CH2O, etc.). Once entries are selected, this option has no effect on the search. 
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
    name: "selected_criterion_taginput",
    props: ['selectedCriterion'],
    data() { 
        return {
            inputTags: [],
            startsWith: true
        } 
    },
    updated() {
        this.inputTags = this.selectedCriterion.tags;
    },
    computed: {
        ...mapGetters('criterion', [
            'filters'
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),
        getTagList() {
            return this.selectedCriterion.searchData;
        }
    },
    methods: {
        ...mapActions('criterion', [
            'updateSelectedTags',
            'updateSearchData'
        ]),
        ...mapActions('adsorption-measurement', [
            'fetchTableData'
        ]),
        async updateTags(newTags) {
            let uuid = this.selectedCriterion.uuid;
            newTags = _.cloneDeep(newTags);
            await this.updateSelectedTags({uuid, newTags});

            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({filters, tableDataColumns});

            let newSearchData = this.getAllTags();
            this.updateSearchData({uuid, newSearchData});

        },
        getAllTags() {
            return _.map(this.selectedCriterion.filterData, 
                this.selectedCriterion.selectedSearchCriterion.column_name)
                .filter(item => item != null)
                .filter(item => item.length > 0)
        },
        filterTags(text) {
            let newSearchData = this.getAllTags().filter((option) => {
                if (this.startsWith) {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) == 0;
                } else {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0;
                }
                
            });
            let uuid = this.selectedCriterion.uuid;
            this.updateSearchData({uuid, newSearchData});
        }
    }
}
</script>

<style scoped>
.taginput, .dropdown {
    display: inline-block;
    vertical-align: middle;
}
</style>
