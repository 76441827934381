<template>
    <b-field>
        <form>
            <div class="container"><b>Overall Formula:  {{label}}</b></div>

            <div class="container" v-for="(reference_species, index) of content" 
                v-bind:key="reference_species.id"> 
                <br>
                <div class="container"><b><u>Reference Species {{index+1}}</u></b></div>
                <div class="container">Coefficient: {{reference_species.reference_coefficient}}</div>
                <div class="container">Formula: {{reference_species.species.formula}}</div>
                <div class="container">Molecular Formula: {{reference_species.species.molecular_formula}}</div>
                <div class="container">SMILES: {{reference_species.species.smiles}}</div>
                <div class="container">Connectivity SMILES: {{reference_species.species.connectivity_smiles}}</div>
                <div class="container">Name: {{reference_species.species.name}}</div>
            </div>
        </form>
    </b-field>
</template>

<script>
    export default {
        name: 'adsorption-reference-species-content',
        props: [
            'label',
            'content'
        ]
    }
</script>

<style>

</style>
