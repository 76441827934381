import Vue from 'vue';
import App from './App.vue';
import vueConfig from 'vue-config';
import config from './config';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import aws_exports from './aws-exports';
import Buefy from 'buefy';
// import 'buefy/dist/buefy.css';
import './styles/bulma.scss';
import '@mdi/font/css/materialdesignicons.css';
import TreeView from 'vue-json-tree-view';
import store from './store'
import router from './router';
import VueLogger from 'vuejs-logger';
import AsyncComputed from 'vue-async-computed';
import VPopover from 'vue-js-popover';
import VueCustomToolTip from '@adamdehaven/vue-custom-tooltip';

// import VueRouter from 'vue-router';
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
console.log( 'aws-exports: ', aws_exports);

Amplify.configure(aws_exports);

Vue.config.productionTip = false;
Vue.use(vueConfig, config);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(Buefy);
Vue.use(TreeView);
Vue.use(router);
Vue.use(VueLogger, options);
Vue.use(AsyncComputed);
Vue.use(VPopover, { tooltip: true });
Vue.use(VueCustomToolTip, {
  name: 'VueCustomTooltip',
  color: '#fff',
  background: '#1e76ba',
  borderRadius: 12,
  fontWeight: 400
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
