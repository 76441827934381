<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Adsorption Energy Data
            </p>
        </header>
        <div class="card-content">
            <b-field>
                <div class="container">
                    <div class="container">Adsorption Energy: {{adsorptionEnergy.energy}} <span v-if="adsorptionEnergy.energy">eV</span></div>
                    <div class="container">Most Stable Site: {{adsorptionEnergy.mostStable}}</div>
                    <br>
                    <div v-if="adsorptionEnergy.gpeInfo">
                        <div class="container"><b><u>RSI Calculation</u></b></div>
                        <div class="container">ZPE Considered?: {{adsorptionEnergy.gpeInfo.is_zpe}}</div>
                        <div class="container">Current RS GPEs:
                            <li v-for="gpe in adsorptionEnergy.gpeInfo.current_ref_species_gpe" :key="gpe.id">
                                Energy: {{ gpe.energy }}, ZPE: {{ gpe.zero_point_energy }}
                            </li>
                        </div>
                        <div class="container">Target RS GPEs:
                            <li v-for="gpe in adsorptionEnergy.gpeInfo.target_ref_species_gpe" :key="gpe.id">
                                Energy: {{ gpe.energy }}, ZPE: {{ gpe.zero_point_energy }}
                            </li>
                        </div>
                        <div class="container">Original Energy: {{adsorptionEnergy.gpeInfo.original_energy}} <span v-if="adsorptionEnergy.gpeInfo.original_energy">eV</span></div>
                        <div class="container">Energy Adjustment: {{adsorptionEnergy.gpeInfo.energy_adjustment}} <span v-if="adsorptionEnergy.gpeInfo.energy_adjustment">eV</span></div>
                    </div>
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'adsorption-energy-detail',
        props: ['adsorptionEnergy']
    }
</script


<style>

</style>
