<template>
    <div class="">

        <component 
            v-if="tooltipContent()" 
            :is="tooltipContent()" 
            :content="content" 
            :label="label" 
        />

        <div v-else class="missing-tooltip-content">Missing Content</div>

    </div>
</template>

<script>
    export default {
        name: 'ccb-tooltip-content',
        components: {
            // Add any new components to tooltipContent() method below
            'adsorbate-species-content': () => import('@/components/contents/adsorbate-species-content'),
            'adsorption-reference-species-content': () => import('@/components/contents/adsorption-reference-species-content'),
            'column-header-content': () => import('@/components/contents/column-header-content'),
        },
        props: {
            content: [Array, Object, String],
            label: String,
            type: String,
        },
        methods: {
            tooltipContent() {
                // grab appropriate content component by type or return false
                switch(this.type) {
                    case 'adsorbate-species-content':
                        return 'adsorbate-species-content';
                    case 'adsorption-reference-species-content':
                        return 'adsorption-reference-species-content';
                    case 'column-header-content':
                        return 'column-header-content';
                    default:
                        return false;
                }
            }
        }
    }
</script>