<template>
    <div class="columns">
        <div class="column">
            <b-input     
                type="number"
                placeholder="Coefficient"
                @blur="coefficientInput"
                v-model="coefficient"
                min="0"
                step="0.01" />
                <!-- use :placeholder="`value`" to add an example to the field -->
        </div>
        
        <div class="column">
            <b-select placeholder="Reference Species" v-model="species" @input="speciesInput">
                <option v-for="species in speciesData"
                    :key="species.id"
                    :value="{formula: species.formula, id: species.id, molecular_formula: species.molecular_formula, elemental_formula: species.elemental_formula}">
                    {{ species.formula }}
                </option>
            </b-select>
        </div>
        
        <div class="column">
            <a @click="trashButtonClicked" v-if="displayTrashButton"><b-icon icon="trash-can-outline"/></a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
    name: 'coefficientAndAdsorbate',

    props: ['uuid'],
     
    data() {
        return {
            coefficient: null,
            species: null
        }
    },

    async mounted() {
        const index = _.findIndex(this.coefficientsAndAdsorbates, (el) => el.uuid === this.uuid);
        const { coefficient, species } = this.coefficientsAndAdsorbates[index];
        this.species = species;
        this.coefficient = coefficient;
        await this.fetchSpeciesData();
    },
    
    computed: {
        ...mapGetters('species', ['speciesData']),
        ...mapGetters('adsorption-measurement', ['coefficientsAndAdsorbates']),

        displayTrashButton() {
            return this.coefficientsAndAdsorbates.length > 1;
        }
    },
    
    methods: {
        ...mapActions('species', ['fetchSpeciesData']),

        ...mapActions('adsorption-measurement', [
            'removeCoefficientAndAbsorbate',
            'updateCoefficient',
            'updateSpecies',
            'updateOverallFormula',
            'updateElementalFormula'
        ]),

        trashButtonClicked() {
            const { uuid } = this;
            this.removeCoefficientAndAbsorbate({ uuid });
            this.updateOverallFormula();
            this.updateElementalFormula();
        },

        coefficientInput() {
            const { coefficient, uuid } = this;
            const data = { uuid, coefficient: parseFloat(coefficient) }
            this.updateCoefficient(data);
            this.updateOverallFormula();
            this.updateElementalFormula();
        },

        speciesInput() {
            const { species, uuid } = this;
            const data = { uuid, species };
            this.updateSpecies(data);
            this.updateOverallFormula();
            this.updateElementalFormula();
        }
    }
}
</script>

<style>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
</style>
