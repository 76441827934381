import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      component: require('@/components/Home').default,
      name: 'home',
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user',
      name: 'user-details',
      component: require('@/components/User/Details').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/healthcheck',
      name: 'healthcheck',
      component: require('@/components/Compcat/health-check').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/search_database',
      name: 'search_database',
      component: require('@/components/Compcat/search-database').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/search_database_experimental',
      name: 'search_database_experimental',
      component: require('@/components/Compcat/search-database-experimental').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/search_database_gpe',
      name: 'search_database_gpe',
      component: require('@/components/Compcat/search-database-gpe').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/signin',
      name: 'user-sign-in',
      component: require('@/components/User/sign-in').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/oauth2/callback',
      name: 'user-oauth2-callback',
      component: require('@/components/User/oauth2-callback').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/signup',
      name: 'user-sign-up',
      component: require('@/components/User/sign-up').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/user/signedout',
      name: 'user-signed-out',
      component: require('@/components/User/signed-out').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/release-notes',
      name: 'release-notes-index',
      component: require('@/components/release-notes/index').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/parameter-guide',
      name: 'parameter-guide-index',
      component: require('@/components/parameter-guide/index').default,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/disclaimer',
      name: 'disclaimer-index',
      component: require('@/components/disclaimer/index').default,
      meta: {
        requiresAuth: false
      }
    }
  ],
  mode: 'history'//process.env.NODE_ENV == 'development' ? 'hash' : 'history'
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log('Checking auth . . . ');
    try {
      // await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      await store.dispatch('auth/getCurrentAuthenticatedUser');
      return next();
    } catch (error) {
      // console.log('Not signed in . . . ', error);
      // console.error(error);
      Vue.prototype.$Amplify.Auth.federatedSignIn();
      // return next({
      //   path: '/signin',
      //   query: {
      //     redirect: to.fullPath
      //   }
      // });
    }
  }
  next();
});

export default router;
