import rp from 'request-promise';
import config from '../../config';
import store from '../../store';

let getBaseUrl = () => {
    return config.apiUrl;
}

let getBearerToken = () => {
    return `Bearer ${store.getters['auth/currentAuthenticatedUser'].signInUserSession.idToken.jwtToken}`
}

const AdsorptionMeasurementService = {
    list: async (filter={}, for_download=false, rsi_conversion=false, rsi_coefficient_adsorbates=[], rsi_elemental_formula=[]) => {
        let requestOptions = {
            url: getBaseUrl() + 'v2/adsorption_measurement',
            // url: getBaseUrl() + 'adsorption_measurement',
            qs: {
                'filter': JSON.stringify(filter),
                for_download,
                rsi_conversion,
                rsi_coefficient_adsorbates: JSON.stringify(rsi_coefficient_adsorbates),
                rsi_elemental_formula: JSON.stringify(rsi_elemental_formula)
            },
            json: true,
            method: 'GET',
        };
    
        let response = await rp( requestOptions );

        return response;
    }
}


export { AdsorptionMeasurementService };