import { SearchCriteriaService } from '../../services/compcat/search-criteria'
import { ExperimentalDataService } from '../../services/compcat/experimental-data'
import { GPEDataService } from '../../services/compcat/gas-phase-energy'
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const state = {
    searchCriteria: [], 
    selectedCriteria: [],
    presetCriteria: []
}

// Preset criteria defaults. These are always popualted
// in the search criteria.
//
// TODO: Refactor these constants to their own JS module.

const presetMostStableDefault = {
    "uuid": "presetMostStable",
    "selectedSearchCriterion": {
        "id": 4,
        "default_display_order": 7,
        "display_order": 7,
        "criteria": "Most Stable",
        "table_name": "adsorption_measurement",
        "column_name": "is_most_stable_site",
        "filter_key": "is_most_stable_site",
        "display_key": "is_most_stable_site",
        "display_type": "simple",
        "display_field": "most_stable",
        "display_label": "Most Stable",
        "visible": true,
        "default": true,
        "datatype": "Boolean",
        "notes": null
    },
    "filterData": [],
    "searchData": [],
    "tags": [],
    "numberRange": [],
    "numbers": [],
    "checkbox": true
};

const presetUnitCellDefault = {
    uuid: 'presetUnitCell',
    selectedSearchCriterion: {
        datatype: 'Preset Unit Cell',

        // There are multiple filter_keys based off of this one component:
        //
        // bulk_surface_property_set.is_stretched
        // bulk_surface_property_set.is_compressed
        //
        // Setting filter_key to null here prevents undefined errors during
        // filter string construction.
        filter_key: null
    },
    normal: true,
    stretched: false,
    compressed: false
}

const presetCoverageDefault = {
    uuid: 'presetCoverage',
    selectedSearchCriterion: {
        datatype: 'Numeric',
        filter_key: 'adsorbate_fraction.numeric'
    },
    numbers: [0, 0.34]
}

const getters = {
    searchCriteria: (state) => {
        return state.searchCriteria;
    },
    selectedCriteria: (state) => {
        return state.selectedCriteria;
    },
    presetCriteria: (state) => {
        console.log( 'preset criteria: ', state.presetCriteria);
        return state.presetCriteria;
    },
    filters: (state) => {
        let filter = {};
        // Merge the two criteria arrays
        let allSelectedCriteria = state.presetCriteria.concat(state.selectedCriteria);
        _.each(allSelectedCriteria, (selectedCriterion) => {
            let data_type = selectedCriterion.selectedSearchCriterion.datatype;
            let filter_key = selectedCriterion.selectedSearchCriterion.filter_key;

            if (data_type === 'String') {
                if (selectedCriterion.tags.length == 0) {
                    delete filter[filter_key];
                }
                else {
                    filter[filter_key] = { '$in': selectedCriterion.tags };
                }
            }
            else if (data_type === 'Numeric') {
                let [minNumber, maxNumber] = selectedCriterion.numbers;
                if (minNumber === null && maxNumber === null) {
                    delete filter[filter_key];
                }
                else if (minNumber === maxNumber) {
                    filter[filter_key] = { '$equals': minNumber }
                }
                else {
                    if (minNumber !== null && maxNumber !== null) {
                        filter[filter_key] = { '$gte': minNumber, '$lte': maxNumber };
                    }
                    else if (minNumber === null) {
                        filter[filter_key] = { '$lte': maxNumber };
                    }
                    else if (maxNumber === null) {
                        filter[filter_key] = { '$gte': minNumber }
                    }
                }
            }
            else if (data_type === 'Boolean') {
                // Prevent null being passed as a filter string
                if (selectedCriterion.checkbox === null) {
                    delete filter[filter_key];
                } else {
                    filter[filter_key] = selectedCriterion.checkbox;
                }
            }
            else if (data_type == 'Preset Unit Cell') {
                let { normal, stretched, compressed } = selectedCriterion;

                // add filters for stretched and compressed as a starting point
                filter['bulk_surface_property_set.is_stretched'] = stretched;
                filter['bulk_surface_property_set.is_compressed'] = compressed;
                    
                // remove filters based on checkboxes
                if (normal) {
                    if (stretched) {
                        delete filter['bulk_surface_property_set.is_stretched'];
                    }
                    if (compressed) {
                        delete filter['bulk_surface_property_set.is_compressed'];
                    }
                    // finally, we will have no filter, if normal, stretched, and compressed are checked
                }
                else {
                    if (stretched && compressed) {
                        // do not use these conditions for filter
                        delete filter['bulk_surface_property_set.is_compressed'];
                        delete filter['bulk_surface_property_set.is_stretched'];
                        // build a new filter for 'OR'
                        let or_conditions = []
                        or_conditions.push({"bulk_surface_property_set.is_stretched" : "true"});
                        or_conditions.push({"bulk_surface_property_set.is_compressed" : "true"});
                        filter["$or"] = or_conditions;
                    }
                    else {
                        if (stretched) {
                            delete filter['bulk_surface_property_set.is_compressed'];
                        }
                        if (compressed) {
                            delete filter['bulk_surface_property_set.is_stretched'];
                        }    
                    }
                }
            }
        })
        console.log('Database query:', filter);
        return filter;
    }
}

const actions = {
    async resetSelectedCriteria ({ commit }) {
        commit ( 'resetSelectedCriteria' );
    },
    async fetchSearchCriteria ({ commit }) {
        let searchCriteria = await SearchCriteriaService.list();
        commit( 'setSearchCriteria', searchCriteria );
    },
    async fetchExperimentalSearchCriteria ({ commit }) {
        let searchCriteria = await ExperimentalDataService.get_search_criteria();
        commit( 'setSearchCriteria', searchCriteria );
    },
    async fetchGPESearchCriteria ({ commit }) {
        let searchCriteria = await GPEDataService.get_search_criteria();
        commit( 'setSearchCriteria', searchCriteria );
    },
    async addSelectedCriteria ({ commit }) {
        let selectedCriterion = {
            uuid: uuidv4(),
            selectedSearchCriterion: {},
            filterData: [],
            searchData: [],
            tags: [], 
            numberRange: [],
            numbers: [], 
            checkbox: false,
        };
        commit ( 'addSelectedCriteria', selectedCriterion );
    },
    async updateSelectedCriteria ({ commit } , {uuid, newSearchCriterion}) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        let filterData = [];
        let searchData = [];
        let numberRange = [];
        commit ( 'updateSelectedCriteria', {index, newSearchCriterion} );

        if (newSearchCriterion.datatype === 'String') {
            filterData = await SearchCriteriaService.get_distinct(
                newSearchCriterion.table_name, 
                newSearchCriterion.column_name);
            searchData = _.map(filterData, newSearchCriterion.column_name);
        }
        else if (newSearchCriterion.datatype === 'Numeric') {
            numberRange = await SearchCriteriaService.get_range(newSearchCriterion.table_name, newSearchCriterion.column_name);
        }
        commit ( 'updateSelectedCriteriaData', {index, filterData, searchData, numberRange} );
    },
    async updateSelectedTags ({ commit } , {uuid, newTags}) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        commit ( 'updateSelectedTags', {index, newTags} );
    },
    async updateSelectedNumbers ({ commit } , {uuid, newNumbers}) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        commit ( 'updateSelectedNumbers', {index, newNumbers} );
    },
    async updateSelectedCheckbox ({ commit } , {uuid, newCheckbox}) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        commit ( 'updateSelectedCheckbox', {index, newCheckbox} );
    },
    async updatePresetCheckbox ({ commit } , {uuid, newCheckbox}) {
        let index = _.findIndex(state.presetCriteria, {uuid:uuid});
        commit ( 'updatePresetCheckbox', {index, newCheckbox} );
    },
    async updatePresetUnitCell({ commit }, { uuid, normal, stretched, compressed }) {
        let index = _.findIndex(state.presetCriteria, { uuid })
        commit('updatePresetUnitCell', { index, normal, stretched, compressed });
    },
    async removeSelectedCriterion({ commit }, {uuid}) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        commit ( 'removeSelectedCriterion', {index} );
    },
    updateSearchData( {commit}, {uuid, newSearchData} ) {
        let index = _.findIndex(state.selectedCriteria, {uuid:uuid});
        commit ( 'updateSearchData', {index, newSearchData} );
    },
    updatePresetCoverageAction( {commit}, {uuid, newNumbers} ) {
        let index = _.findIndex(state.presetCriteria, {uuid:uuid});
        commit('updatePresetCoverageMutation', {index, newNumbers})
    }
}

const mutations = {
    resetSelectedCriteria (state) {
        state.selectedCriteria = [];
        state.presetCriteria = [
            presetMostStableDefault,
            presetUnitCellDefault,
            presetCoverageDefault
        ];
    },
    setSearchCriteria (state, searchCriteria) {
        state.searchCriteria = searchCriteria;
    },
    addSelectedCriteria (state, selectedCriteria) {
        state.selectedCriteria.push(selectedCriteria);
    },
    updateSelectedCriteria (state, {index, newSearchCriterion}) {
        _.set(state.selectedCriteria[index], 'selectedSearchCriterion', newSearchCriterion);
    },
    updateSelectedCriteriaData ( state, {index, filterData, searchData, numberRange}) {
        _.set(state.selectedCriteria[index], 'filterData', filterData);
        _.set(state.selectedCriteria[index], 'searchData', searchData);
        _.set(state.selectedCriteria[index], 'numberRange', numberRange);
        _.set(state.selectedCriteria[index], 'tags', []);
        _.set(state.selectedCriteria[index], 'numbers', []);
        _.set(state.selectedCriteria[index], 'checkbox', false);
    },
    updateSelectedTags (state, {index, newTags}) {
        _.set(state.selectedCriteria[index], 'tags', newTags);
    },
    updateSelectedNumbers (state, {index, newNumbers}) {
        _.set(state.selectedCriteria[index], 'numbers', newNumbers);
    },
    updateSelectedCheckbox (state, {index, newCheckbox}) {
        _.set(state.selectedCriteria[index], 'checkbox', newCheckbox);
    },
    updatePresetCheckbox (state, {index, newCheckbox}) {
        _.set(state.presetCriteria[index], 'checkbox', newCheckbox);
    },
    updatePresetUnitCell (state, { index, normal, stretched, compressed }) {
        _.set(state.presetCriteria[index], 'normal', normal);
        _.set(state.presetCriteria[index], 'stretched', stretched);
        _.set(state.presetCriteria[index], 'compressed', compressed);
    },
    removeSelectedCriterion (state, {index}) {
        state.selectedCriteria.splice(index, 1);
    },
    updateSearchData(state, {index, newSearchData}) {
        _.set(state.selectedCriteria[index], 'searchData', newSearchData);
    },
    updatePresetCoverageMutation(state, {index, newNumbers}) {
        _.set(state.presetCriteria[index], 'numbers', newNumbers);
    }
}

const criterion = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default criterion;
