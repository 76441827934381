<template>
  <div class="container">
    <section class="section">

    <b-steps size="is-medium" v-model="activeStep" :has-navigation="false">
        <b-step-item label="Sign In" icon="lock">
          <!-- <h1 class="title">User Pool</h1>
          <b-field label="User Pool">
            <b-input
              type="username"
              v-model="$Amplify.Auth.userPool.clientId">
            </b-input>
          </b-field>
          <h1 class="title">Sign In</h1> -->
          <div class="container">
            <section class="section">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="submitSignin">
                    <b-field label="Username">
                      <b-input
                        type="username"
                        v-model="data.username">
                      </b-input>
                    </b-field>
                    <b-field label="Password">
                      <b-input
                        type="password"
                        v-model="data.password">
                      </b-input>
                    </b-field>
                    <button class="button is-primary" type="submit">Sign In</button>
                  </form>
                </div>
              </div>
            </section>
          </div>

        </b-step-item>
        <b-step-item label="Confirm" icon="account-badge-outline">

          <div class="container">
            <section class="section">
              <div class="columns">
                <div class="column is-6 is-offset-3">

                  <div v-if="!mfaType" class="container has-text-centered">
                    <section class="section">
                      <div>
                        <p>
                          <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                          </b-icon>
                        </p>
                        <p>MFA is not configured.</p>
                      </div>
                      <button class="button is-primary is-outline" @click="finishSignIn()">Skip MFA</button>
                    </section>
                  </div>


                  <form v-else @submit.prevent="submitCode">
                    <b-field label="Code">
                      <b-input
                        type="code"
                        v-model="data.code">
                      </b-input>
                    </b-field>
                    <button class="button is-primary" type="submit">Confirm Code</button>
                  </form>
                </div>
              </div>
            </section>
          </div>


        </b-step-item>
    </b-steps>

    </section>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'user-sign-in',
  data () {
    return {
      data: {
        username: '',
        password: '',
        code: ''
      },
      activeStep: 0,
      mfaType: ''
    }
  },
  computed: {
    ...mapGetters('auth', [
      'cognitoUser',
      'currentAuthenticatedUser'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'confirmSignIn',
      'getCurrentAuthenticatedUser'
    ]),
    async submitSignin () {
      try {
        let username = this.data.username;
        let password = this.data.password;
        await this.login({username, password});

        if (this.cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.mfaType = 'SOFTWARE_TOKEN_MFA';
        }

        this.activeStep = 1;

      } catch (error) {
        // console.error(error);
      }
    },
    async submitCode () {
      try {
        let user = this.cognitoUser;
        let code = this.data.code;
        let mfaType = this.mfaType;
        await this.confirmSignIn({user, code, mfaType});
        // console.log("cognitoUser", cognitoUser);
        await this.finishSignIn();
      } catch (error) {
        // console.log('ERROR', error);
      }
    },
    async finishSignIn () {
      // console.log(this.$router.history.current.query.redirect);
      await this.getCurrentAuthenticatedUser();
      await this.$router.push(this.$router.history.current.query.redirect || '/user');
    }
  }
}
</script>

<style>

</style>
