<template>
  <footer class="footer nrel-footer">
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-4 logo-emn img-center"><a href="http://energy.gov/eere/energy-materials-network/energy-materials-network" target="_blank"><img src="./assets/logo-emn-bw.png" class="img-responsive emn" alt="Energy Materials Network logo"></a></div>
          <div class="column is-8">
            <div class="columns">
              <div class="column is-3"><a href="http://www.nrel.gov/" target="_blank"><img src="./assets/logo-nrel-bw.png" class="img-responsive img-center" alt="National Renewable Energy Laboratory logo"></a></div>
              <div class="column is-3"><a href="https://www.pnl.gov/" target="_blank"><img src="./assets/logo-pnnl-bw.png" class="img-responsive img-center" alt="Pacific Northwest National Laboratory logo"></a></div>
              <div class="column is-3"><a href="https://www.ornl.gov/" target="_blank"><img src="./assets/logo-oak-ridge-bw.png" class="img-responsive img-center" alt="Oak Ridge National Laboratory logo"></a></div>
              <div class="column is-3"><a href="https://www.netl.doe.gov/" target="_blank"><img src="./assets/logo-netl_white.png" class="img-responsive img-center" alt="National Energy Technology Laboratory logo"></a></div>
            </div>
            <div class="columns">
              <div class="column is-3"><a href="http://www.lanl.gov/" target="_blank"><img src="./assets/logo-los-alamos-bw.png" class="img-responsive img-center" alt="Los Alamos National Laboratory logo"></a></div>
              <div class="column is-3"><a href="http://www.anl.gov/" target="_blank"><img src="./assets/logo-argonne-bw.png" class="img-responsive img-center" alt="Argonne National Laboratory logo"></a></div>
              <div class="column is-3"><a href="http://www.sandia.gov/" target="_blank"><img src="./assets/logo-sandia-nat-lab-bw.png" class="img-responsive img-center" alt="Sandia Laboratory logo"></a></div>
              <div class="column is-3"><a href="http://www.inl.gov/" target="_blank"><img src="./assets/logo-idaho-nat-lab-bw.png" class="img-responsive img-center" alt="Idaho National Laboratory logo"></a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
  export default {
    name: 'app-footer'
  }
</script>

<style>

#page-tools {
  margin-bottom: 4em;
}

footer.nrel-footer {
  color: #000;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height:  1.5;
  padding-top: 3em;
 }

footer.nrel-footer .header {
  border-bottom:1px solid #5a5b5b;
  font-size: 14px;
  margin-top: 1em;
  text-transform: uppercase;
  margin-bottom:10px;
}
@media(min-width: 768px) {
  footer .header {
      margin-top: 0;
  }
}

footer.nrel-footer .nrel-attr {
  font-size: 10px;
  margin: 0 0 10px;
}


footer.nrel-footer .social-links {
  font-size: 14px;
  margin:0
}
footer.nrel-footer .social-links li {
  padding: 0;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline > li {
  display: inline-block;
  margin-right:5px;
}

footer.nrel-footer .logo {
  display: block;
  max-width:100px;
  margin-top: 1em;
}

footer.nrel-footer ul {
  list-style-type: none;
  margin-left:0;
  margin-bottom: 0;
  padding-left: 0;
}

footer.nrel-footer .border-right {
  border-right: 1px solid #5a5b5b;
}

footer.nrel-footer ul.dotlist li:before { /* nbsp middot nbsp */
  content: "\00a0  \00b7  \00a0";
}

footer.nrel-footer ul.dotlist li:first-child:before {
  content: "";
}
</style>
