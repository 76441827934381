<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        CPD Parameter Guide
      </h1>
      <h1 class="subtitle">
        This page gives an explanation for the parameters included in CPD entries. The CPD currently contains density functional theory-computed surface adsorption energies. The parameters reflect this application.
      </h1>
      <br>

      <h1 class="title">
        Bulk Properties
      </h1>
      <h1 class="subtitle">
        The properties of the bulk material from which a surface slab or cluster model has been generated for the adsorption energy calculation.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Bulk Formula</p>
                <div class="content">
                  The chemical formula of the bulk material.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-success">Lattice Constants, &#8491; (x3)</p>
                <div class="content">
                  The lattice constants, in angstroms, give the edge dimensions of the unit cell in the bulk crystal structure for the material. Depending on the symmetry of the unit cell (<a href="https://dictionary.iucr.org/Laue_class" target="_blank">Laue class</a>), 1–3 may be reported.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-success">Numeric</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Primary Class</p>
                <div class="content">
                  Metadata describing primary characteristics of the bulk material, such as "transition metal".
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Secondary Class</p>
                <div class="content">
                  Metadata describing secondary characteristics of the bulk material, such as "bulk alloy".
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Space Group</p>
                <div class="content">
                  The <a href="https://en.wikipedia.org/wiki/Space_group" target="_blank">space group</a> describes the three-dimensional symmetry of the bulk crystal structure.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-primary">Stretched?</p>
                <div class="content">
                  Some calculations introduce a bulk lattice deformation. This field indicates whether the lattice was stretched relative to its relaxed (optimized) lattice parameters.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-primary">Compressed?</p>
                <div class="content">
                  Some calculations introduce a bulk lattice deformation. This field indicates whether the lattice was compressed relative to its relaxed (optimized) lattice parameters.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title">
        Surface/Particle Properties
      </h1>
      <h1 class="subtitle">
        The properties of the surface slab or cluster model generated for the adsorption energy calculation.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Facet</p>
                <div class="content">
                  Which crystalline facet was used to form the surface slab model? Using <a href="https://en.wikipedia.org/wiki/Miller_index" target="_blank">Miller index</a> notation.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Surface Termination</p>
                <div class="content">
                  For many multicomponent materials, after defining a particular facet (cut angle), there are multiple possible surface terminations depending on the precise cut point along the Miller index direction. This field describes the outermost atoms terminating the surface slab or nanoparticle cluster. For example, for a Pt-Pd alloy material, Pt, Pd, or mixed Pt-Pd terminations are possible.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Surface (1st Layer) Composition</p>
                <div class="content">
                  Chemical composition of the surface layer.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Sub-Surface (2nd Layer) Composition</p>
                <div class="content">
                  Chemical composition of the sub-surface layer, i.e., the layer between the surface and the bulk.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Cell Symmetry</p>
                <div class="content">
                  This field describes the relation of the surface slab to a single unit cell, in both size and relative shape. For example, 1x1 indicates a single instance of the unit cell in the periodically repeated directions (typically x and y), whereas 2x2 would indicate four total unit cells in the slab. Other, non-primitive unit cells apply the naming conventions used to describe overlayers in surface science.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-success">Nanoparticle Size</p>
                <div class="content">
                  For nanoparticle calculations only, how many atoms comprise the nanoparticle cluster? (excluding adsorbate atoms)
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-success">Numeric</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title">
        Methods
      </h1>
      <h1 class="subtitle">
        Describes the specific <a href="https://en.wikipedia.org/wiki/Density_functional_theory" target="_blank">density-functional theory</a> methods used to calculate the adsorption energy.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Software</p>
                <div class="content">
                  Commercial software package or custom code used for the calculation.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Potential</p>
                <div class="content">
                  <a href="https://en.wikipedia.org/wiki/Density_functional_theory#Pseudo-potentials" target="_blank">Pseudopotential</a> used to approximate potentials felt by valence electrons as a result of interactions with the nucleus and core electrons, without explicit calculation of core electron states.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Basis Set</p>
                <div class="content">
                  <a href="https://en.wikipedia.org/wiki/Basis_set_(chemistry)" target="_blank">Basis set</a> used to expand the valence electron wave functions.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Exchange Correlation (XC) Functional</p>
                <div class="content">
                  <a href="https://en.wikipedia.org/wiki/Density_functional_theory#Approximations_(exchange%E2%80%93correlation_functionals)" target="_blank">Exchange-correlation functional</a> used to approximate the exchange-correlation energy.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-primary">Fixed Substrate?</p>
                <div class="content">
                  Was the slab or nanocluster model completely fixed during adsorbate optimization (TRUE), or was any portion of the model allowed to relax (FALSE)?
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-primary">Spin Polarized?</p>
                <div class="content">
                  Were spin-polarized calculations performed? In spin polarized calculations the up and down spins in each electron pair are not restricted. This method is commonly used for materials and species with a non-zero magnetic moment.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-primary">Zero Point Energy (ZPE) Corrected?</p>
                <div class="content">
                  Were the calculated energies corrected using the <a href="https://en.wikipedia.org/wiki/Zero-point_energy" target="_blank">zero-point energy</a>?
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      
      <h1 class="title">
        Adsorbate and Reference Species: Overview
      </h1>
      <div class="block">
        <h1 class="subtitle">
          An adsorption energy is always calculated as the energy of a surface-adsorbed molecule relative to the sum of the energy of a) the "empty" surface and b) an isolated gas-phase molecular reference. The gas-phase reference may be the same molecule as the adsorbate but simply isolated in space, or it may be a different set of molecules having the same overall formula as the adsorbate. It can even be composed of one or more molecules minus another, such as H2 on a Pt surface being referenced to Pt and H2O – 0.5 O2.
        </h1>
      </div>
      <div class="block">
      <h1 class="title">
        Adsorbate
      </h1>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-blue">Adsorbate Species</p>
                <div class="content">
                  The molecular species adsorbed on the surface. The Adsorbate/Reference Species section below provides more details on the info included for each species.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-blue">Species Record</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Adsorption Site</p>
                <div class="content">
                  Metadata describing the surface site of adsorption and/or binding configuration. Note that this field is not standardized. Users should consult the linked reference for site and configuration naming conventions.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Coverage Fraction</p>
                <div class="content">
                  Surface coverage of the adsorbate species (in units of monolayer, ML), typically defined as number of adsorbates/number of surface sites. Represented as a fraction string. What consititutes a surface site for this data value is defined in the Coverage Fraction Unit.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title has-text-success">Coverage Fraction – Numeric</p>
                <div class="content">
                  A numeric (decimal) value for the coverage fraction, calculated automatically from the Coverage Fraction string.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-success">Numeric</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Coverage Fraction Unit</p>
                <div class="content">
                  Defines what constitutes a surface site (e.g., for a Pt(111) site, the coverage fraction would be per Pt).
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title">
        Gas-Phase Reference Set
      </h1>
      <h1 class="subtitle">
        The reference set is composed of one or more pairs of a species, from the adsorbate/reference species table below, and the coefficient for that species in the balanced adsorption reaction (e.g., 1 for H2O and -0.5 for O2 in <b>Pt + H2O – 0.5 O2 &#8594; Pt–H2</b>)
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-blue">Reference Species</p>
                <div class="content">
                  The gas-phase reference species. Each database entry must have at least one. The Adsorbate/Reference Species section below provides more details on the info included for each species.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-blue">Species Record</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">1+ Required</span>
                    </div>
                  </div>
                </div>
              </article>
              
            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-success">Coefficient</p>
                <div class="content">
                  The stoichiometric coefficient for the reference species in the balanced adsorption energy equation. The coefficient is multiplied by the calculated gas-phase energy of the reference species when computing the adsorption energy.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-success">Numeric</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">1+ Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title has-text-blue">
        Adsorbate/Reference Species
      </h1>
      <h1 class="subtitle">
        Details on the chemical structure of adsorbate/reference species.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Structural Formula</p>
                <div class="content">
                  The <a href="https://en.wikipedia.org/wiki/Structural_formula#Condensed_formulas" target="_blank">condensed structural formula</a> of the species (e.g., CH3OH for methanol).
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">SMILES</p>
                <div class="content">
                  Chemical structure of the species represented using the <a href="https://en.wikipedia.org/wiki/Simplified_molecular-input_line-entry_system" target="_blank">Simplified molecular-input line-entry system</a> (SMILES). SMILES strings in the CPD are canonicalized using the <a href="http://opensmiles.org/" target="_blank">OpenSMILES</a> implementation in <a href="https://github.com/openbabel" target="_blank">OpenBabel</a> to prevent inclusion of multiple entries of the same structure.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Molecular Formula</p>
                <div class="content">
                  <a href="https://en.wikipedia.org/wiki/Chemical_formula#Molecular_formula" target="_blank">Molecular formula</a> for the species using <a href="https://en.wikipedia.org/wiki/Chemical_formula#Hill_system" target="_blank">Hill notation</a> (e.g., ethylene's molecular formula is C2H4).
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

              <article class="tile is-child notification">
                <p class="title">Connectivity SMILES</p>
                <div class="content">
                  A version of the SMILES that contains only connectivity information, replacing all multiple bonds with single bonds and adding explicit hydrogen counts, where needed, to preserve connectivity and structural formula. Because the bonding of the adsorbate species is often indeterminate, species having the same Connectivity SMILES are treated as identical in the CPD.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">

              <article class="tile is-child notification">
                <p class="title">Name</p>
                <div class="content">
                  The chemical name for the species (common or IUPAC).
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title">
        Adsorption Energy
      </h1>
      <h1 class="subtitle">
        Computed adsorption energy data.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title has-text-success">Adsorption Energy, eV</p>
                <div class="content">
                  Calculated adsorption energy in electron volts (eV), denoting the strength of interaction between the adsorbate and substrate. This is the key output of the calculation.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-success">Numeric</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent is-vertical">

              <article class="tile is-child notification">
                <p class="title has-text-primary">Most Stable Site/Configuration?</p>
                <div class="content">
                  Of the adsorption sites and configurations studied in the paper for this surface and adsorbate, was this the adsorption site/configuration found to have the lowest energy (i.e., energetically preferred)?
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-primary">Boolean</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

      <h1 class="title">
        Source Metadata
      </h1>
      <h1 class="subtitle">
        Information on the source of the record.
      </h1>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">DOI</p>
                <div class="content">
                  Digital object identifer (DOI) for the peer-reviewed publication that the data is reported in.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              
              <article class="tile is-child notification">
                <p class="title">Uploaded By</p>
                <div class="content">
                  Name, email, and institutional affiliation for the user who uploaded the data point.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-danger">Required</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-vertical">

              <article class="tile is-child notification">
                <p class="title">Notes</p>
                <div class="content">
                  Additional details, if any, that did not fit into other fields.
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="control">
                    <div class="tags has-addons">
                      <span class="tag is-dark">String</span>
                    </div>
                  </div>
                </div>
              </article>

            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'parameter-guide-index'
}
</script>

<style>

</style>
