<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          You have been signed out.
        </h1>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'user-signed-out'
}
</script>

<style>

</style>
