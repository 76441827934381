const pkg = require('../package.json');
// const ssm = require('./services/aws/ssm');
var exports = module.exports = {};
var config = {};

config.version = pkg.version;

config.baseUrl = process.env.VUE_APP_BASEURL;
config.apiUrl = process.env.VUE_APP_APIURL;
config.userPoolId = process.env.VUE_APP_COGNITO_POOL_ID;
config.userPoolWebClientId = process.env.VUE_APP_COGNITO_APP_CLIENT_ID;
config.domain = process.env.VUE_APP_OAUTH_DOMAIN;
config.loadTimeout = process.env.VUE_APP_LOAD_TIMEOUT;

config.isIFrame = false;
if (window.location !== window.parent.location) {
    config.isIFrame = true;
}

Object.assign(exports, config);
