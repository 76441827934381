<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Adsorption Reference Species
            </p>
        </header>
        <div class="card-content">
            <adsorptionReferenceSpeciesContent
                :content="adsorptionReferenceSpeciesSet"
                :label="coefficientFormula"
            >
            </adsorptionReferenceSpeciesContent>
        </div>
    </div>
</template>

<script>
    import adsorptionReferenceSpeciesContent from '../contents/adsorption-reference-species-content'
    export default {
        name: 'adsorption-reference-species-detail',
        components: {
            adsorptionReferenceSpeciesContent
        },
        props: [
            'adsorptionReferenceSpeciesSet',
            'coefficientFormula'
        ]
    }
</script


<style>

</style>
