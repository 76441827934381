<template>
    <div class="card is-size-7">
        <header class="card-header">
            <p class="card-header-title">
                Adsorption Measurement Detail
            </p>
        </header>
        <footer class="card-footer">
            <materialPropertiesDetail
                :materialProperties="materialProperties"
            >
            </materialPropertiesDetail>
            <surfacePropertiesDetail
                :surfaceProperties="surfaceProperties"
            >
            </surfacePropertiesDetail>
            <adsorptionMethodsDetail
                :adsorptionMethods="adsorptionMethods"
            >
            </adsorptionMethodsDetail>
            <adsorbateSpeciesDetail
                :adsorbateSpecies="adsorbateSpecies"
            >
            </adsorbateSpeciesDetail>
            <adsorptionReferenceSpeciesDetail
                :adsorptionReferenceSpeciesSet="adsorptionMeasurement.adsorption_reference_species_set"
                :coefficientFormula="coefficientFormula"
            >
            </adsorptionReferenceSpeciesDetail>
            <adsorptionMetadataDetail
                :adsorptionMetadata="adsorptionMetadata"
            >
            </adsorptionMetadataDetail>
            <adsorptionEnergyDetail
                :adsorptionEnergy="adsorptionEnergy"
            >
            </adsorptionEnergyDetail>
        </footer>
    </div>
</template>

<script>
import adsorbateSpeciesDetail from '../details/adsorbate-species-detail';
import adsorptionReferenceSpeciesDetail from '../details/adsorption-reference-species-detail';
import adsorptionEnergyDetail from '../details/adsorption-energy-detail';
import adsorptionMetadataDetail from '../details/adsorption-metadata-detail';
import materialPropertiesDetail from '../details/material-properties-detail';
import adsorptionMethodsDetail from '../details/adsorption-methods-detail';
import surfacePropertiesDetail from '../details/surface-properties-detail';

    export default {
        name: 'adsorption-measurement-detail',
        components: {
            adsorbateSpeciesDetail,
            adsorptionReferenceSpeciesDetail,
            adsorptionEnergyDetail,
            adsorptionMetadataDetail,
            materialPropertiesDetail,
            adsorptionMethodsDetail,
            surfacePropertiesDetail
        },
        props: [
            'adsorptionMeasurement',
            'coefficientFormula',
            'adsorbateSpecies',
            'materialProperties',
            'surfaceProperties',
            'adsorptionMethods',
            'adsorptionMetadata',
            'adsorptionEnergy'
        ]
    }
</script


<style>

</style>
