import config from './config';

const awsconfig = {
  Auth: {
    scopes: ['openid'],
    region: 'us-west-2',
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: config.domain,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.baseUrl + 'oauth2/callback',
      redirectSignOut: config.baseUrl + 'user/signedout',
      responseType: 'token'
    }
  }
};

export default awsconfig;                           