<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Adsorbate Species
            </p>
        </header>
        <div class="card-content">
            <adsorbateSpeciesContent
                :content="adsorbateSpecies"
            >
            </adsorbateSpeciesContent>
        </div>
    </div>
</template>

<script>
    import adsorbateSpeciesContent from '../contents/adsorbate-species-content'
    export default {
        name: 'adsorbate-species-detail',
        components: {
            adsorbateSpeciesContent
        },
        props: [
            'adsorbateSpecies'
        ]
    }
</script


<style>

</style>
