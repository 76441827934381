<template>
  <div id="app">
    <nrelHeader></nrelHeader>
    <navBar></navBar>
    <router-view></router-view>
    <nrelFooter></nrelFooter>
    <footer class="footer is-bottom">
      <div class="container">
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-dark">version</span>
              <span class="tag is-white">{{$config.version}}</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-dark">api version</span>
              <span class="tag is-white">{{apiVersion}}</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag is-white"><a href="/release-notes">release notes</a></span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import navBar from './nav-bar.vue';
import nrelHeader from './nrel-header.vue';
import nrelFooter from './nrel-footer.vue';
import healthcheck from './services/compcat/healthcheck' // For API version

export default {
  name: 'app',
  components: {
    navBar,
    nrelHeader,
    nrelFooter,
  },
    data() {
    return {
      apiVersion: ''
    }
  },
  async mounted() {
    let apiVersion = await healthcheck.version(); // Hacky; this should really be done in store instead
    this.apiVersion = apiVersion.version || 'n/a';
  },
}
</script>


<style lang="scss">
footer.is-bottom {
  padding-top: 3em;
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
 }

footer.is-bottom a,
footer.is-bottom a:visited {
  color: #000;
  text-decoration: none;

}

footer.is-bottom a:hover {
  color:#c60;
}
</style>
