<template>
  <div class="container">

    <section class="section">
      <h1 class="title">Current User Identity</h1>
      <div v-if="hasCredentials">
        <b-field label="Account">
          <b-input :value="identity.Account" custom-class="is-static" readonly></b-input>
        </b-field>
        <b-field label="Arn">
          <b-input :value="identity.Arn" custom-class="is-static" readonly></b-input>
        </b-field>
        <b-field label="UserId">
          <b-input :value="identity.UserId" custom-class="is-static" readonly></b-input>
        </b-field>
      </div>
      <div v-else>
        No Current User Credentials
      </div>
    </section>

    <section class="section">
      <h1 class="title">Current User Credentials</h1>
      <div v-if="hasCredentials">
        <b-field label="Access Key Id">
          <b-input :value="currentUserCredentials.accessKeyId" custom-class="is-static" readonly></b-input>
        </b-field>
        <b-field label="Secret Access Key">
          <b-input :value="currentUserCredentials.secretAccessKey" custom-class="is-static" readonly></b-input>
        </b-field>
        <b-field label="Session Token">
          <b-input :value="currentUserCredentials.sessionToken" type="textarea" custom-class="is-static" readonly></b-input>
        </b-field>
      </div>
      <div v-else>
        No Current User Credentials
      </div>
    </section>

    <section class="section">
      <h1 class="title">Cognito Identity</h1>

      <b-field label="Username">
        <b-input :value="cognitoUser.username" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Preferred MFA">
        <b-input :value="cognitoUser.preferredMFA" custom-class="is-static" readonly></b-input>
      </b-field>

      <h2 class="subtitle">Attributes</h2>
      <b-field label="Name">
        <b-input :value="cognitoUserAttributes.name" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Email">
        <b-input :value="cognitoUserAttributes.email" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Verified Email">
        <b-input :value="'' + cognitoUserAttributes.email_verified" custom-class="is-static" readonly></b-input>
      </b-field>

      <h2 class="subtitle">Client</h2>
      <b-field label="Endpoint">
        <b-input :value="cognitoUserClient.endpoint" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="User Agent">
        <b-input :value="cognitoUserClient.userAgent" custom-class="is-static" readonly></b-input>
      </b-field>

      <h2 class="subtitle">Current Session</h2>
      <b-field label="Access Token"
        message="Must use the access token to call API Gateway URLs when Cognito Authroizer is configured with scopes.">
        <b-input
          :value="accessToken"
          type="textarea"
          custom-class="is-static"
          readonly></b-input>
      </b-field>

      <b-field label="Id Token"
        message="Must use the identity token to call API Gateway URLs when Cognito Authroizer is configured with out scope access.">
        <b-input
          :value="idToken"
          type="textarea"
          custom-class="is-static"
          readonly></b-input>
      </b-field>

      <b-field label="Cognito Groups">
        <b-taginput
          v-model="cognitoUser.signInUserSession.idToken.payload['cognito:groups']"
          ellipsis
          icon="label"
          placeholder="Add a group"
          :remove-on-keys=[]>
        </b-taginput>
      </b-field>

      <b-field label="Cognito Roles">
        <b-taginput
          v-model="cognitoUser.signInUserSession.idToken.payload['cognito:roles']"
          ellipsis
          icon="label"
          :remove-on-keys=[]>
        </b-taginput>
      </b-field>


      <b-field label="Cognito Preferred Role">
        <b-input :value="cognitoUser.signInUserSession.idToken.payload['cognito:preferred_role']" custom-class="is-static" readonly></b-input>
      </b-field>

      <b-field label="OAuth Scope">
        <b-taginput
          :value="cognitoUser.signInUserSession.accessToken.payload.scope.split(',')"
          ellipsis
          icon="label"
          disabled>
        </b-taginput>
      </b-field>

      <b-field label="Refresh Token">
        <b-input :value="refreshToken" type="textarea" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Clock Drift">
        <b-input :value="currentSession.clockDrift" custom-class="is-static" readonly></b-input>
      </b-field>

      <!-- <h2 class="subtitle">User Pool</h2>
      <b-field label="User Pool Id">
        <b-input :value="cognitoUser.pool.userPoolId" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Client Id">
        <b-input :value="cognitoUser.pool.clientId" custom-class="is-static" readonly></b-input>
      </b-field>
      <b-field label="Advanced Security Data Collection Flag">
        <b-input :value="'' + cognitoUser.pool.advancedSecurityDataCollectionFlag" custom-class="is-static" readonly></b-input>
      </b-field> -->
    </section>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'user-details',
    data () {
      return {
        // identity: {}
      }
    },
    async mounted() {
      // console.log('User/Details.vue::mounted')
      try {
        await this.getCurrentAuthenticatedUser();
        // console.log('currentAuthenticatedUser', this.currentAuthenticatedUser);
        await this.getCurrentSession();
        // console.log('currentSession', this.currentSession);
      } catch (error) {
        // console.error('ERROR -- User/Details.vue::mounted', error)
        this.$router.push('user-sign-in')
      }
      // try {
      //   await this.getAccessKeys();
      // } catch (error) {
      //   console.error(error);
      // }
    },
    computed: {
      ...mapGetters('auth', [
        'currentAuthenticatedUser',
        'currentUserCredentials',
        'currentSession',
        'cognitoUser',
        'hasCredentials',
        'identity'
      ]),
      cognitoUserAttributes() {
        return this.cognitoUser.attributes || {}
      },
      cognitoUserClient() {
        return this.cognitoUser.client || {}
      },
      accessToken() {
        if (!this.currentSession || !this.currentSession.accessToken)
          return ''
        return this.currentSession.accessToken.jwtToken
      },
      idToken() {
        if (!this.currentSession || !this.currentSession.idToken)
          return ''
        return this.currentSession.idToken.jwtToken;
      },
      refreshToken() {
        if (!this.currentSession || !this.currentSession.refreshToken)
          return ''
        return this.currentSession.refreshToken.token;
      }
    },
    methods: {
      ...mapActions('auth', [
        'getCurrentAuthenticatedUser',
        'getCurrentUserCredentials',
        'getCurrentSession',
        'getIdentity'
      ]),
      dropdownChanged: () => {
        // console.log('dropdownChanged');
      },
      getAccessKeys: async function() {
        await this.getCurrentUserCredentials();
        if (!this.hasCredentials) {
          return
        }

        try {
          await this.getIdentity();
        } catch (error) {
          this.$notify({
            group: 'error',
            title: error.name,
            text: error.message
          });
        }
      }
    }
  }
</script>

<style>
</style>
