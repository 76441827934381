<template>
    <b-field>
        <div class="container">
            <div class="container"><b><u>Adsorbate</u></b></div>
            <div class="container">Formula: {{(content.formula === null) ? '' : content.formula}}</div>
            <div class="container">Molecular Formula: {{(content.molecularFormula === null) ? '' : content.molecularFormula}}</div>
            <div class="container">SMILES: {{(content.smiles === null) ? '' : content.smiles}}</div>
            <div class="container">Connectivity SMILES: {{(content.connectivitySmiles === null) ? '' : content.connectivitySmiles}}</div>
            <div class="container">Name: {{(content.name === null) ? '' : content.name}}</div>
            
            <br>
            <div class="container">Adsorption Site: {{(content.adsorptionSite === null) ? '' : content.adsorptionSite}}</div>
            <div v-if="content.fraction">
                <div class="container">Coverage Fraction: {{(content.fraction === null) ? '' : content.fraction}}</div>
            </div>
            <div v-if="content.numeric">
                <div class="container">Coverage Fraction - Numeric: {{(content.numeric === null) ? '' : content.numeric}}</div>
            </div>
        </div>
    </b-field>
</template>

<script>

    export default {
        name: 'adsorbate-species-content',
        props: [
            'content',
        ]
    }
</script>

