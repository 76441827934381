<template>
    <div>
        <div class="modal-card-body">
            <b-field 
                grouped 
                group-multiline
            >
                <p v-for="(column, index) in getTableDataColumns"
                    :key="index"
                    class="control"
                >
                    <b-checkbox 
                        v-model="column.visible"
                        @input="updateDataColumnVisible"
                        type="is-info"
                    >
                        {{ column.label }}
                    </b-checkbox>
                </p>
            </b-field>
        </div>
        <footer class="modal-card-foot">
            <b-button
                @click="allDataColumnVisible">
                Select All
            </b-button>
            <b-button
                @click="resetColumnDisplay">
                Reset Selections
            </b-button>
            <!-- <b-button
                @click="resetColumnOrder">
                Reset Order
            </b-button> -->
        </footer>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import _ from 'lodash';

    export default {
        name: 'column_manager',
        data() {
            return {
                checkboxGroup: []
            }
        },
        computed: {
            ...mapGetters('criterion', [
                'filters'
            ]),
            ...mapGetters('adsorption-measurement', [
                'tableDataColumns'
            ]),
            getTableDataColumns() {
                this.checkboxGroup = _.cloneDeep(this.tableDataColumns);
                return this.checkboxGroup;
            },
        },
        methods: {
            ...mapActions('adsorption-measurement', [
                'toggleColumnVisible',
                'resetColumnVisible',
                'updateColumnVisible',
                'fetchTableData'
            ]),
            updateDataColumnVisible() {
                _.each(this.checkboxGroup, (column) => {
                    let fieldName = column.field;
                    let visible = column.visible;
                    this.updateColumnVisible({fieldName, visible});
                })
                this.updateTableData();
            },
            allDataColumnVisible() {
                _.each(this.checkboxGroup, (column) => {
                    let fieldName = column.field;
                    let visible = true;
                    this.updateColumnVisible({fieldName, visible});
                })
                this.updateTableData();
            },
            async updateTableData() {
                let filters = this.filters;
                let tableDataColumns = this.tableDataColumns;
                await this.fetchTableData({filters, tableDataColumns});
            },
            resetColumnDisplay() {
                _.each(this.tableDataColumns, (column) => {
                    let fieldName = column.field;
                    this.resetColumnVisible({fieldName});
                })
                this.updateTableData();
            },
            resetColumnOrder() {

            }
        }
    }
</script>

<style>

</style>
