<template>
    <b-tooltip 
        dashed
        position="is-right"
        :ref="label"
        :class="`${active ? 'active' : ''}`"
    >
        <span 
            @click="active = true"
            @mouseover="active = true"
            @mouseleave="active = false"
        >{{label}}</span>

        <template v-slot:content>
            <tooltipContent
                :content="content"
                :label="label"
                :type="type"
            >
            </tooltipContent>
        </template>

    </b-tooltip>
</template>

<script>
    import tooltipContent from '../tooltips/ccb-tooltip-content';

    export default {
        name: 'ccb-tooltip',
        components: {
            tooltipContent
        },
        props: {
            content: [Array, Object, String],
            label: String,
            type: String, // Used to control which content format is needed
        },
        data() {
            return {
                active: false,
                tooltipCopy: null,
            }
        },
        watch: {
            active(isActive) {
                if (isActive) {
                    // Place tooltip outside of parent overflow element
                    const tooltipParent = this.$refs[this.label].$el;
                    const parentBox = tooltipParent.getBoundingClientRect();
                    const tooltipContent = tooltipParent.childNodes[0];
                    // Make sure content is on page
                    tooltipContent.style.display = 'block';
                    // Collect box properties (height needed)
                    const contentBox = tooltipContent.getBoundingClientRect();
                    // Clone content and inner html (true)
                    this.tooltipCopy = tooltipContent.cloneNode(true);
                    // Avoid clipping tooltip off bottom of screen
                    const boxBottom = window.innerHeight - (contentBox.height + parentBox.top);
                    const bottomOffset = boxBottom >= 0 ? 0 : (boxBottom - 10); // This is a negative number
                    // Set offsets
                    this.tooltipCopy.style.top = (parentBox.top + bottomOffset) + 'px';
                    this.tooltipCopy.style.left = (parentBox.left + parentBox.width + 10) + 'px';
                    this.tooltipCopy.style.display = 'block';
                    this.tooltipCopy.style.opacity = 1;
                    this.tooltipCopy.style.visibility = 'visible';
                    // Add original classes back to content
                    this.tooltipCopy.classList.add('orange-tooltip-content');
                    // Append new tooltip outside of overflow parent
                    document.querySelector('body').append(this.tooltipCopy);
                } else {
                    // Replace tooltip from whence it came
                    this.tooltipCopy.remove();
                }
            }
        }
    }
</script>

<style lang="scss">
    // Styles are used on tooltip-content component

    .tooltip-content {
        visibility: hidden; // Hiding default tooltip
    }

    .b-tooltip.active .tooltip-trigger {
        border-bottom: 4px solid $ccb-orange;
    }
    .orange-tooltip-content {
        position: fixed;
        background: $ccb-orange;
        color: #fff;
        width: auto;
        padding: 0.5rem 0.75rem !important;
        border-radius: 6px;
        font-size: 0.85rem;
        font-weight: 400;
        -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, .20);
        box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, .20);
        // white-space: nowrap;
        z-index: 38;
        max-width: 400px;
    }
</style>