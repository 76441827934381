<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Metadata 
            </p>
        </header>
        <div class="card-content">
            <b-field>
                <div class="container">
                    <div class="container">DOI: <a :href="'https://doi.org/' + adsorptionMetadata.doi" target="_blank">{{adsorptionMetadata.doi}}</a></div>
                    <div class="container">Note: {{adsorptionMetadata.external_note}}</div>
                    <!-- Do not show internal note in detail view -->
                    <!-- <div class="container">Internal Note: {{adsorptionMetadata.internal_note}}</div> -->
                    <br>
                    <div class="container"><b><u>Uploaded By</u></b></div>
                    <div class="container">Name: {{adsorptionMetadata.emnUser}}</div>
                    <div class="container">Email: {{adsorptionMetadata.email}}</div>
                    <div class="container">Affiliation: {{adsorptionMetadata.affiliation}}</div>
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'adsorbate-metadata-detail',
        props: ['adsorptionMetadata']
    }
</script


<style>

</style>
