<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        Welcome to the Catalyst Property Database
      </h1>
      <h2 class="subtitle has-text-primary">
        Making it easier to find and apply theoretical simulation data in catalyst development research
      </h2>
      <div class="block">
        The ChemCatBio Catalyst Property Database (CPD) currently contains DFT-computed adsorption energies for surface species in catalytic reactions. It is designed to <strong>reduce the time required to perform literature searches on previously computed catalytic pathways and intermediates</strong> by providing data in a central, searchable location. It also aims to enable accelerated discovery of catalyst descriptor and property correlations that are a common bottleneck in predictive approaches to catalyst R&amp;D.
      </div>
      <div class="block">
        <strong>Get started with your first <a href="/search_database">database search</a>.</strong>
      </div>
      <article class="message is-info">
        <div class="message-header">
          New version! CPD v1.4.0 released 2024/08/31
        </div>
        <div class="message-body">
          See the <a href="/release-notes">release notes</a> for more details.
        </div>
      </article>
      <div class="block content">
        <h3>
          Features of the CPD
        </h3>
        <ul>
          <li>
            Initial dataset of DFT-computed adsorption energies for common surface species in catalytic reactions, drawn from peer-reviewed sources
          </li>
          <li>
            Detailed metadata accompanies each entry, allowing for ease of comparison and identification
          </li>
          <li>
            Advanced search and filter logic available to help users find the data of interest, quickly
          </li>
        </ul>
      </div>
      <div class="block content">
        <h3>
          Coming Soon
        </h3>
        <ul>
          <li>
            Updates to the web application to meet user needs for catalyst R&amp;D applications
          </li>
          <li>
            More data!
          </li>
        </ul>
      </div>
      <div class="block">
        The CPD was developed as part of the Chemical Catalysis for Bioenergy Consortium (ChemCatBio) Data Hub project and released to the public in September, 2020. For more information on its ongoing development, check back here or email <a href="mailto:cpd@nrel.gov">cpd@nrel.gov</a>.
      </div>
      <div class="block">
        See the changes introduced in each version of the CPD in the <a href="/release-notes">release notes</a>.
      </div>
      <p class="is-size-7">
        Results generated by use of this Software are dependent on many variables, including the quality of the data entered by the user and any assumptions made by the user in relation to data inputs.  Accordingly, DOE, NREL, and ALLIANCE cannot guarantee any results generated by use of the Software and user is entirely responsible for the results and any reliance on the results.  User shall not claim that DOE/NREL/ALLIANCE authenticate or otherwise agree with the results generated by the Software. (<a href="/disclaimer">View the full disclaimer</a>)
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'home'
}
</script>

<style>

</style>
