<template>
    <section>
        <span :style="{display:'none'}">{{selectedCriterion}}</span>
        <b-field horizontal>
            <b-field label="Min" horizontal>
                <b-input @input="updateMin" v-model="min" type="number" step="any" 
                    :min="initialMin"
                    :max="initialMax"></b-input>
            </b-field>
            <b-field label="Max" horizontal>
                <b-input @input="updateMax" v-model="max" type="number" step="any" 
                    :min="initialMin"
                    :max="initialMax"></b-input>
            </b-field>
        </b-field>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "selected_criterion_numeric",
    props: ["selectedCriterion"],
    data() { 
        return {
            min: null,
            max: null,
            initialMin: 0,
            initialMax: 0
        } 
    },
    async mounted() {
        let uuid = this.selectedCriterion.uuid;       
        let newNumbers = [this.selectedCriterion.numberRange[0].min, 
            this.selectedCriterion.numberRange[0].max];
        await this.updateSelectedNumbers({uuid, newNumbers});
        let filters = this.filters;
        let tableDataColumns = this.tableDataColumns;
        await this.fetchTableData({filters, tableDataColumns});
    },
    updated() {
        this.initialMin = this.selectedCriterion.numberRange[0].min;
        this.initialMax = this.selectedCriterion.numberRange[0].max;
        if (this.min === null && this.max === null) {
            this.min = this.initialMin;
            this.max = this.initialMax;
        }
    },
    computed: {
        ...mapGetters('criterion', [
            'filters'        
        ]),
        ...mapGetters('adsorption-measurement', [
            'tableDataColumns'
        ]),
        getMinLabel() {
            if (this.selectedCriterion && this.selectedCriterion.numberRange.length)
                return `Min: ${this.selectedCriterion.numberRange[0].min}`
            return 'min';
        },
        getMaxLabel() {
            if (this.selectedCriterion && this.selectedCriterion.numberRange.length)
                return `Max: ${this.selectedCriterion.numberRange[0].max}`
            return 'max';
        }
    },
    methods: {
        ...mapActions('criterion', [
            'updateSelectedNumbers'
        ]),
        ...mapActions('adsorption-measurement', [
            'fetchTableData',
        ]),
        async updateMin(newMin) {
            let uuid = this.selectedCriterion.uuid;       
            let newNumbers = [newMin, this.max];
            await this.updateSelectedNumbers({uuid, newNumbers});
            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({filters, tableDataColumns});
        },
        async updateMax(newMax) {
            let uuid = this.selectedCriterion.uuid;
            let newNumbers = [this.min, newMax];
            await this.updateSelectedNumbers({uuid, newNumbers});
            let filters = this.filters;
            let tableDataColumns = this.tableDataColumns;
            await this.fetchTableData({filters, tableDataColumns});
        },
    }
}
</script>


<style scoped>
/* No margin for parent b-field */
::v-deep .field-label {
    flex-grow: 0;
    margin-right: 0;
}

::v-deep .field-body .field-label {
    /* Align b-field label and b-input text */
    padding-top: 7px;
    /* Re-style labels */
    margin-right: 0;
    padding-right: 8px;
    flex-grow: 0;
    text-align: left;
}

::v-deep .field-body .field:not(:last-child) {
    margin-right: 12px !important;
}
</style>
