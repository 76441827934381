<template>
  <div class="container">
    <section class="section">

    </section>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'user-oauth2-callback',
  data () {
    return {
      data: {
        username: '',
        password: '',
        code: ''
      },
      activeStep: 0,
      mfaType: ''
    }
  },
  async mounted() {
    await this.finishSignIn();
  },
  computed: {
    ...mapGetters('auth', [
      'cognitoUser',
      'currentAuthenticatedUser'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'confirmSignIn',
      'getCurrentAuthenticatedUser'
    ]),
    async submitSignin () {
      try {
        let username = this.data.username;
        let password = this.data.password;
        await this.login({username, password});

        if (this.cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.mfaType = 'SOFTWARE_TOKEN_MFA';
        }

        this.activeStep = 1;

      } catch (error) {
        // console.error(error);
      }
    },
    async submitCode () {
      try {
        let user = this.cognitoUser;
        let code = this.data.code;
        let mfaType = this.mfaType;
        await this.confirmSignIn({user, code, mfaType});
        // console.log("cognitoUser", cognitoUser);
        await this.finishSignIn();
      } catch (error) {
        // console.log('ERROR', error);
      }
    },
    async finishSignIn () {
      // console.log(this.$router.history.current.query.redirect);
      await this.getCurrentAuthenticatedUser();
      await this.$router.push(this.$router.history.current.query.redirect || '/user');
    }
  }
}
</script>

<style>

</style>
