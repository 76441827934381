<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Bulk Properties
            </p>
        </header>
        <div class="card-content">
            <b-field>
                <div class="container">
                    <div class="container">Bulk Formula: {{materialProperties.formula}}</div>
                    <div class="container">Primary Class: {{materialProperties.primaryClass}}</div>
                    <div class="container">Secondary Class: {{materialProperties.secondaryClass}}</div>
                    <div class="container">Space Group: {{materialProperties.spaceGroup}}</div>
                    <div class="container">Stretched: {{materialProperties.stretched}}</div>
                    <div class="container">Compressed: {{materialProperties.compressed}}</div>
                    <div v-if="materialProperties.latticeConstant">
                        <div class="container">Lattice Constant 1: {{materialProperties.latticeConstant.lattice_constant_1}} <span v-if="materialProperties.latticeConstant.lattice_constant_1">&#8491;</span></div>
                        <div class="container">Lattice Constant 2: {{materialProperties.latticeConstant.lattice_constant_2}} <span v-if="materialProperties.latticeConstant.lattice_constant_2">&#8491;</span></div>
                        <div class="container">Lattice Constant 3: {{materialProperties.latticeConstant.lattice_constant_3}} <span v-if="materialProperties.latticeConstant.lattice_constant_3">&#8491;</span></div>
                    </div>
                    <div v-else>
                        <div class="container">Lattice Constant 1:</div>
                        <div class="container">Lattice Constant 2:</div>
                        <div class="container">Lattice Constant 3:</div>
                    </div>
                </div>
            </b-field>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'material-properties-detail',
        props: ['materialProperties']
    }
</script


<style>

</style>
